export const VoxelShadersWater = {
  vertexShader: `

varying vec3 vColor;
varying vec2 vUV1;
varying vec2 vUV2;
uniform float uTime;

void main() {
  vec2 sinPos = sin(position.xz * 0.1 + uTime*0.02) * 0.125;

  vUV1 = uv + vec2(uTime*0.04+14.0*sin(position.x * 0.3 + uTime*0.125)*0.25*(0.3+sinPos.x)+1.,uTime*0.06);
  vUV2 = uv + vec2(uTime*0.01+14.0*sin(position.z * 0.3 + uTime*0.125)*0.15*sinPos.x,-uTime*0.04);
  vColor = color;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position.xyz, 1.);
}`,
  fragmentShader: `
  varying vec2 vUV1;
  varying vec2 vUV2;
  varying vec3 vColor;
  uniform sampler2D uTexture1;
  uniform sampler2D uTexture2;
  uniform float uTime;

  vec4 hash4( vec2 p ) { return fract(sin(vec4( 1.0+dot(p,vec2(37.0,17.0)),
                                                2.0+dot(p,vec2(11.0,47.0)),
                                                3.0+dot(p,vec2(41.0,29.0)),
                                                4.0+dot(p,vec2(23.0,31.0))))*103.0); }
  vec4 textureNoTile( sampler2D samp, in vec2 uv )
  {
      vec2 p = floor( uv );
      vec2 f = fract( uv );

      // derivatives (for correct mipmapping)
      vec2 ddx = dFdx( uv );
      vec2 ddy = dFdy( uv );

      // voronoi contribution
      vec4 va = vec4( 0.0 );
      float wt = 0.0;
      for( int j=-1; j<=1; j++ )
      for( int i=-1; i<=1; i++ )
      {
          vec2 g = vec2( float(i), float(j) );
          vec4 o = hash4( p + g );
          vec2 r = g - f + o.xy;
          float d = dot(r,r);
          float w = exp(-5.0*d );
          vec4 c = textureGrad( samp, uv + o.zw, ddx, ddy );
          va += w*c;
          wt += w;
      }

      // normalization
      return va/wt;
  }

void main() {
  vec4 color1 = textureNoTile(uTexture1, vUV1);
  vec4 color2 = textureNoTile(uTexture2, vUV2+color1.gb*10.);
  vec4 color3 = textureNoTile(uTexture1, vUV1+color2.gb*10.);
  vec3 color = color3.rgb*color2.rgb;

  float border = 1.0-(vColor.r+color1.r*0.5-color2.r*0.3)*1.5 + sin(uTime+vUV1.x)*0.1;
  border = clamp(border*1., 0.0, 1.0);
  // border = border > 0.6 ? 1. : 0.;

  color = vColor*(pow(color,vec3(3.,3.5,2.)) + vec3(4.0) * border);

  color = clamp(color, 0.0, 1.0);

  gl_FragColor = vec4(color,1.0);

}`
}