export const VoxelCoverShaders = {
  vertexShader: `

varying vec2 vDiff1;
varying vec2 vDiff2;
varying vec2 vUV;
uniform float uTime;

void main() {
  vUV = position.xz;
  vDiff1 = uv.xy-position.xz;
  vDiff2 = color.xy-position.xz;

  vec3 pos = position.xyz;
  float mTime = (uTime + sin(uTime*2.)*0.3)*16.0;
  float effect1 = (sin(position.x*8.0+mTime) + sin(position.z*8.0+mTime)) * (1.-color.z)*0.0025 * sin(uTime*0.5);
  float effect2 = (sin(position.x*8.0+mTime+1.) + sin(position.z*8.0+mTime+2.)) * (1.-color.z)*0.0025 * sin(uTime*0.5);
  float effect3 = (sin(position.x*8.0+mTime+3.) + sin(position.z*8.0+mTime+7.)) * (1.-color.z)*0.0025 * sin(uTime*0.5);

  pos.y += effect1;
  pos.x += effect2*0.5;
  pos.z += effect3*0.5;

  gl_Position = projectionMatrix * modelViewMatrix * vec4(pos.xyz, 1.);
}`,
  fragmentShader: `
  varying vec2 vDiff1;
  varying vec2 vDiff2;
  varying vec2 vUV;
  uniform sampler2D uTexture;

  vec4 hash4( vec2 p ) { return fract(sin(vec4( 1.0+dot(p,vec2(37.0,17.0)),
                                                2.0+dot(p,vec2(11.0,47.0)),
                                                3.0+dot(p,vec2(41.0,29.0)),
                                                4.0+dot(p,vec2(23.0,31.0))))*103.0); }
  vec4 textureNoTile( sampler2D samp, in vec2 uv )
  {
      vec2 p = floor( uv );
      vec2 f = fract( uv );

      // derivatives (for correct mipmapping)
      vec2 ddx = dFdx( uv );
      vec2 ddy = dFdy( uv );

      // voronoi contribution
      vec4 va = vec4( 0.0 );
      float wt = 0.0;
      for( int j=-1; j<=1; j++ )
      for( int i=-1; i<=1; i++ )
      {
          vec2 g = vec2( float(i), float(j) );
          vec4 o = hash4( p + g );
          vec2 r = g - f + o.xy;
          float d = dot(r,r);
          float w = exp(-5.0*d );
          vec4 c = textureGrad( samp, uv + o.zw, ddx, ddy );
          va += w*c;
          wt += w;
      }

      // normalization
      return va/wt;
  }

void main() {
  vec4 color = textureNoTile(uTexture, vUV);

  vec2 D = vDiff2 - vDiff1;
  float l2 = dot(D, D);
  float t = max(0., min(1., dot(-vDiff1, D) / l2));
  vec2 projection = vDiff1 + t * (D);
  float f = vUV.x + vUV.y ;
  float dist = length(projection)*15.+sin(f*35.)*0.15;//+(color.g-0.5)*1.0;
  //float dist = length(vDiff1)*5.;
  //max(0.,1.-dist*5.)

  float c = 1.0-dist*0.75*color.g;
  color.rgb *= vec3(c,sqrt(c),c);

  if (dist>0.5) {
    discard;
  } else {
    gl_FragColor = vec4(color.rgb*0.75,1.0);
  }

}`
}