function sfc32(a, b, c, d) {
    return function() {
        a >>>= 0;
        b >>>= 0;
        c >>>= 0;
        d >>>= 0;
        var t = (a + b) | 0;
        a = b ^ b >>> 9;
        b = c + (c << 3) | 0;
        c = (c << 21 | c >>> 11);
        d = d + 1 | 0;
        t = t + d | 0;
        c = c + t | 0;
        return (t >>> 0) / 4294967296;
    }
}

function xmur3(str) {
    for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++)
        h = Math.imul(h ^ str.charCodeAt(i), 3432918353),
        h = h << 13 | h >>> 19;
    return function() {
        h = Math.imul(h ^ h >>> 16, 2246822507);
        h = Math.imul(h ^ h >>> 13, 3266489909);
        return (h ^= h >>> 16) >>> 0;
    }
}

export function getRandomGeneratorFromSeed(seedValue) {
    let seed = xmur3(seedValue);
    let rand = sfc32(seed(), seed(), seed(), seed());
    return rand;
}

export function calcMaxValue(octaves, persistence) {
    let maxValue = 0;
    let amplitude = 1;
    for (let o = 0; o < octaves; o++) {
        maxValue += amplitude;
        amplitude *= persistence;
    }
    return maxValue;
}

export function perlinGroup({ octaves, persistence, scale, simplex }) {
    return {
        octaves,
        persistence,
        maxValue: calcMaxValue(octaves, persistence),
        simplex,
        scale
    };
}

export function getPerlin(x, y, { octaves, persistence, maxValue, scale, simplex }) {
    let total = 0;
    let frequency = scale;
    let amplitude = 1;

    for (let o = 0; o < octaves; o++) {
        let res = simplex.noise(x * frequency + 1000 * o, y * frequency);
        // res = res * 0.5 + 0.5;
        total += res * amplitude;
        amplitude *= persistence;
        frequency *= 2;
    }

    total = total / maxValue;
    return total;
}