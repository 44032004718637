// @ts-nocheck
import * as THREE from 'three';
import { Vector3 } from 'three';

export function EmpytMesh() {
    return {
        vertices: [],
        indices: []
    };
}

export function AddToMesh(mesh, subMesh) {

    let vc = mesh.vertices.length;
    mesh.vertices.push(...subMesh.vertices);
    for (let i in subMesh.indices) {
        mesh.indices.push(subMesh.indices[i] + vc);
    }
}

export function CombineMesh(mesh1, mesh2) {
    let vc = mesh1.vertices.length;
    mesh1.vertices = mesh1.vertices.concat(mesh2.vertices);
    let tc = mesh1.indices.length;
    for (let i of mesh2.indices)
        mesh1.indices.push(i + vc);
    return mesh1;
}

export function NormalizeMesh(mesh) {
    const pA = new Vector3(),
        pB = new Vector3(),
        pC = new Vector3();
    const cb = new Vector3(),
        ab = new Vector3();

    mesh.vertices.forEach(v => {
        v.n = new Vector3(0, 0, 0);
    });

    for (let i = 0, il = mesh.indices.length; i < il; i += 3) {

        const vA = mesh.vertices[mesh.indices[i + 0]];
        const vB = mesh.vertices[mesh.indices[i + 1]];
        const vC = mesh.vertices[mesh.indices[i + 2]];

        pA.copy(vA.p);
        pB.copy(vB.p);
        pC.copy(vC.p);

        cb.subVectors(pC, pB);
        ab.subVectors(pA, pB);
        cb.cross(ab);

        vA.n.add(cb);
        vB.n.add(cb);
        vC.n.add(cb);
    }

    mesh.vertices.forEach(v => {
        v.n.normalize();
    });

    return mesh;
}

export function CreateQuadMesh(center, xdir, ydir) {
    let pos = new THREE.Vector3()

    return {
        vertices: [{
            p: center.clone().add(xdir).add(ydir)
        },
        {
            p: center.clone().sub(xdir).add(ydir)
        },
        {
            p: center.clone().sub(xdir).sub(ydir)
        },
        {
            p: center.clone().add(xdir).sub(ydir)
        },
        ],
        indices: [0, 1, 2, 2, 3, 0]
    }

}

export function CreateCubeMesh(size) {
    let sizeHalf = size / 2;
    let mesh = EmpytMesh();

    let xdir = new THREE.Vector3(sizeHalf / 2, 0, 0);
    let ydir = new THREE.Vector3(0, sizeHalf / 2, 0);
    let zdir = new THREE.Vector3(0, 0, sizeHalf / 2);

    let xdir_n = new THREE.Vector3(-sizeHalf / 2, 0, 0);
    let ydir_n = new THREE.Vector3(0, -sizeHalf / 2, 0);
    let zdir_n = new THREE.Vector3(0, 0, -sizeHalf / 2);

    CombineMesh(mesh, CreateQuadMesh(xdir, ydir, zdir));
    CombineMesh(mesh, CreateQuadMesh(ydir, zdir, xdir));
    CombineMesh(mesh, CreateQuadMesh(zdir, xdir, ydir));

    CombineMesh(mesh, CreateQuadMesh(xdir_n, zdir, ydir));
    CombineMesh(mesh, CreateQuadMesh(ydir_n, xdir, zdir));
    CombineMesh(mesh, CreateQuadMesh(zdir_n, ydir, xdir));

    return NormalizeMesh(mesh)
}

