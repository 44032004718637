import {
  EyeShaders,
  FlattenShaders
} from '@/visual/pawn/shaders';
import { ref, computed, watch } from 'vue';
import * as THREE from 'three';
import { Vector3 } from 'three';


function reactiveVector3XYZ(x, y, z) {
  return ref({ x: x || 0, y: y || 0, z: z || 0 });
}

function reactiveVector3(v) {
  return ref(v);
}

export const flattenMaterial = ({
    offset,
    color,
    scale,
    shaders,
    eyeCenter,
    squiggle,
    tilt,
    state,
    curve
  }) =>
  new THREE.ShaderMaterial({
    ...(shaders || FlattenShaders),
    depthWrite: false,
    transparent: true,
    uniforms: {
      uOffset: offset,
      uFlattenAt: { value: new Vector3(0, 0, 0) },
      uScale: scale,
      uColor: color,
      uSquiggle: squiggle || { value: new Vector3(20, 0.0, 0) },
      uEyeCenter: eyeCenter,
      uTilt: tilt,
      uState: state,
      uCurve: curve,
    }
  });


const RingGeometryResolution = 32;
const standardRingGeometry = new THREE.RingGeometry(0, 1, RingGeometryResolution, 2);

function superelipse(geometry, radius, power) {
  const array = geometry.attributes.position.array;
  const standardArray = standardRingGeometry.attributes.position.array;
  for (let i = 0; i < array.length; i += 3) {
    array[i] = Math.pow(Math.abs(standardArray[i]), power) * Math.sign(standardArray[i]) * radius;
    array[i + 1] = Math.pow(Math.abs(standardArray[i + 1]), power) * Math.sign(standardArray[i + 1]) * radius;
  }
  geometry.attributes.position.needsUpdate = true;
}

const SphereGeometryResolution = 32;
const standardSphereGeometry = new THREE.SphereGeometry(1, SphereGeometryResolution, SphereGeometryResolution);

function superelipsiodish(geometry, radius, power) {
  const array = geometry.attributes.position.array;
  const standardArray = standardSphereGeometry.attributes.position.array;
  for (let i = 0; i < array.length; i += 3) {
    const zr = 1;
    // Math.min(1, Math.abs(array[i + 2]) + 0.1);
    // zr = Math.cos(zr * Math.PI / 4);
    array[i] = Math.pow(Math.abs(standardArray[i] / zr), power) * Math.sign(standardArray[i]) * zr * radius.x;
    array[i + 1] = Math.pow(Math.abs(standardArray[i + 1] / zr), power) * Math.sign(standardArray[i + 1]) * zr * radius.y;
  }
  geometry.attributes.position.needsUpdate = true;
}


const FaceDecorator = (face) => (matParams) => {
  const eye = new THREE.Mesh(matParams.geometry || standardRingGeometry, flattenMaterial(matParams));
  face.add(eye);
  return eye;
};


export function GenerateHead() {

  const eyesSquareness = ref(0.95);
  const mouthSquareness = ref(0.5);
  const browSquareness = ref(0.5);
  const headSquareness = ref(0.95);
  const headSize = ref(new THREE.Vector2(1, 1));

  const browYOffset = ref(0.7);
  const browSeparation = ref(0.0);
  const eyeSeperation = ref(0.5);
  const eyeYOffset = ref(0.1);
  const irisLook = ref(0.0);

  const eyeWhiteColor = reactiveVector3XYZ(0.99, 0.95, 0.8);
  const eyeSize = reactiveVector3XYZ(0.7, 0.9, 1);

  const eyeIrisColor = reactiveVector3XYZ(0.99, 0.45, 0.3);
  const eyeIrisSize = reactiveVector3XYZ(0.2, 0.45, 0.7);

  const browColor = reactiveVector3XYZ(0.4, 0.7, 0.8);
  const browSize = reactiveVector3XYZ(0.4, 0.1, 1);
  const browSquiggle = reactiveVector3XYZ(5, -0.1, 0);

  const mouthColor = reactiveVector3XYZ(0.9, 0.9, 0.9);
  const mouthSize = reactiveVector3XYZ(0.2, 0.05, 1);
  const mouthSquiggle = reactiveVector3XYZ(4, 0.1, -0.3);
  const mouthOffset = reactiveVector3XYZ(0, -0.9, 1);

  const eyeStateTilt = reactiveVector3XYZ(-0.25, 0.0, 0.0);
  const eyeStateCurve = reactiveVector3XYZ(4.8, -0.025, 0.0);

  const eyeState = reactiveVector3XYZ(0.35, 0.1, 0.0);


  const eyeGeometry = new THREE.RingGeometry(0, 1, RingGeometryResolution, 2);
  const mouthGeometry = new THREE.RingGeometry(0, 1, RingGeometryResolution, 2);
  const browGeometry = new THREE.RingGeometry(0, 1, RingGeometryResolution, 2);

  superelipse(eyeGeometry, 0.5, eyesSquareness.value)
  superelipse(mouthGeometry, 0.5, mouthSquareness.value)
  superelipse(browGeometry, 0.5, browSquareness.value)

  const eyesSquarenessWatcher = watch(eyesSquareness, () => superelipse(eyeGeometry, 0.5, eyesSquareness.value));
  const mouthSquarenessWatcher = watch(mouthSquareness, () => superelipse(mouthGeometry, 0.5, mouthSquareness.value));
  const browSquarenessWatcher = watch(browSquareness, () => superelipse(browGeometry, 0.5, browSquareness.value));

  const sphereGeometry = new THREE.SphereGeometry(1, SphereGeometryResolution, SphereGeometryResolution);

  superelipsiodish(sphereGeometry, headSize.value, headSquareness.value);

  const headSquarenessWatcher = watch(headSquareness, () => superelipsiodish(sphereGeometry, headSize.value, headSquareness.value));
  const headSizeWatcher = watch(headSize, () => superelipsiodish(sphereGeometry, headSize.value, headSquareness.value));

  const sphereMaterialBack = new THREE.MeshBasicMaterial({
    depthWrite: false,
    color: 0x000000
  });
  const sphereMaterial = new THREE.MeshLambertMaterial({
    depthWrite: false,
    color: 0x334455
  });


  const head = new THREE.Object3D();
  const face = new THREE.Object3D();
  const sphereBack = new THREE.Mesh(sphereGeometry, sphereMaterialBack);
  const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
  sphere.castShadow = true;

  sphereBack.scale.z = 0.71;
  sphereBack.position.z = 0;
  sphereBack.scale.x = sphereBack.scale.y = 1.01;

  sphere.scale.z = 0.8;
  face.scale.z = 0.9;

  // head.add(sphereBack);
  head.add(sphere);
  // sphere.add(face);
  head.position.y = 0;
  // head.scale.z = 2;
  // head.scale.x = 1 * 2;
  // head.scale.y = 1 * 2;

  const eyeLeftOffset = reactiveVector3XYZ(0, 0, 1);
  const eyeRightOffset = reactiveVector3XYZ(0, 0, 1);
  const irisLeftOffset = reactiveVector3XYZ(0, 0, 1);
  const irisRightOffset = reactiveVector3XYZ(0, 0, 1);
  const browOffsetLeft = reactiveVector3XYZ(0, 0, 1);
  const browOffsetRight = reactiveVector3XYZ(0, 0, 1);


  function update() {

    const eyeIrisSeperation = eyeSeperation.value * 0.95;

    //eyeSeperation, eyeYOffset, irisLook, browYOffset, browSeparation

    eyeLeftOffset.value.x = -eyeSeperation.value;
    eyeRightOffset.value.x = eyeSeperation.value;
    eyeLeftOffset.value.y = eyeRightOffset.value.y = eyeYOffset.value;

    irisLeftOffset.value.x = -eyeSeperation.value + irisLook.value;
    irisRightOffset.value.x = eyeSeperation.value * 1 + irisLook.value * 1;
    irisLeftOffset.value.y = irisRightOffset.value.y = eyeYOffset.value;

    browOffsetLeft.value.x = -eyeSeperation.value - browSeparation.value + irisLook.value * 0.5
    browOffsetRight.value.x = eyeSeperation.value * 1 + browSeparation.value * 1 + irisLook.value * 0.5
    browOffsetLeft.value.y = browOffsetRight.value.y = browYOffset.value;

    mouthSquiggle.value.z = irisLook.value * 2;
    browSquiggle.value.z = irisLook.value;
    mouthOffset.value.x = irisLook.value * 0.5;
    // browSeparation.value = irisLook.value * 0.5;

  }

  const addFaceComponent = FaceDecorator(face);

  const faceComponents = {
    eyeLeft: addFaceComponent({
      shaders: EyeShaders,
      offset: eyeLeftOffset,
      color: eyeWhiteColor,
      scale: eyeSize,
      eyeCenter: eyeLeftOffset,
      state: eyeState,
      tilt: eyeStateTilt,
      curve: eyeStateCurve,
      geometry: eyeGeometry
    }),
    eyeRight: addFaceComponent({
      shaders: EyeShaders,
      offset: eyeRightOffset,
      color: eyeWhiteColor,
      scale: eyeSize,
      eyeCenter: eyeRightOffset,
      state: eyeState,
      tilt: eyeStateTilt,
      curve: eyeStateCurve,
      geometry: eyeGeometry
    }),
    // irisLeft: addFaceComponent({
    //   offset: irisLeftOffset,
    //   color: eyeIrisColor,
    //   scale: eyeIrisSize,
    //   shaders: EyeShaders,
    //   eyeCenter: eyeLeftOffset,
    //   state: eyeState,
    //   tilt: eyeStateTilt,
    //   curve: eyeStateCurve,
    //   geometry: eyeGeometry
    // }),
    // irisRight: addFaceComponent({
    //   offset: irisRightOffset,
    //   color: eyeIrisColor,
    //   scale: eyeIrisSize,
    //   shaders: EyeShaders,
    //   eyeCenter: eyeRightOffset,
    //   state: eyeState,
    //   tilt: eyeStateTilt,
    //   curve: eyeStateCurve,
    //   geometry: eyeGeometry
    // }),
    browLeft: addFaceComponent({
      offset: browOffsetLeft,
      color: browColor,
      scale: browSize,
      squiggle: browSquiggle,
      geometry: browGeometry
    }),
    browRight: addFaceComponent({
      offset: browOffsetRight,
      color: browColor,
      scale: browSize,
      squiggle: browSquiggle,
      geometry: browGeometry
    }),
    mouth: addFaceComponent({
      offset: mouthOffset,
      color: mouthColor,
      scale: mouthSize,
      squiggle: mouthSquiggle,
      geometry: mouthGeometry
    })
  };

  return {
    node: head,
    irisLook,
    update,
    properties: {
      //eye stuff
      eyeWhiteColor,
      eyesSquareness,
      eyeSeperation,
      eyeYOffset,
      eyeSize,
      eyeIrisColor,
      eyeIrisSize,
      irisLook,
      eyeState,
      eyeStateTilt,
      eyeStateCurve,

      //brow stuff
      browColor,
      browSquareness,
      browYOffset,
      browSeparation,
      browSize,
      browSquiggle,

      //mouth stuff
      mouthColor,
      mouthSquareness,
      mouthOffset,
      mouthSize,
      mouthSquiggle,

      //head stuff
      headSize,
      headSquareness,
      headSizeWatcher,
    },
    watchers: {
      eyesSquarenessWatcher,
      browSquarenessWatcher,
      mouthSquarenessWatcher,
      headSquarenessWatcher,

    }
  }

}