import Simplex from 'perlin-simplex';
import { rgbToHsl, hslToRgb } from './utils/color';
import { DefaultWorld } from '@/visual/space-scene';
import calculateTileDetail from './utils/calculateTileInfo';

import {
  getRandomGeneratorFromSeed,
  calcMaxValue,
  perlinGroup,
  getPerlin,

} from './utils/noise'; // @ is an alias to /src

// hydration 0-255
// temperature 0-255
// vegetation 0-255
// material: 0-255  //soil/stone/granite/slate/....

export const STM_BIOMS = {
  NONE: 0,
  GRASS: 1,
  DIRT: 2,
  WATER: 3,
  SLATE: 4,
  GRANITE: 5,
  ICE: 6,
  BLUEICE: 7,

};

export function SettlementGenerator() {

  this.setIndex = function(index) {
    this.index = index;
    const rng = {
      random: getRandomGeneratorFromSeed(this.index.toString())
    };

    const simplexes = [
         new Simplex(rng),
         new Simplex(rng),
         new Simplex(rng),
         new Simplex(rng)
       ];

    const randomArray = []
    for (let i = 0; i < 10000; i++) {
      randomArray.push(rng.random());
    }

    const random = (x, y) => {
      return randomArray[(x + y * 115) % 10000];
    }

    const indexInfo = DefaultWorld.fetchInfo(this.index);
    const tileDetail = calculateTileDetail(indexInfo);

    const fertility = tileDetail.fertility;
    const elevation = tileDetail.elevation;
    const tropical = tileDetail.tropical;
    const temp = indexInfo.geology.temp;
    const icey = indexInfo.surfaceType != 2;
    const land = indexInfo.surfaceType != 1;

    let topology = 0.6 + elevation * 0.15;

    if (!land) {
      topology = 0.8;
    }

    const rockyPerlin = perlinGroup({
      octaves: 5,
      persistence: 0.6 * topology,
      scale: 0.5,
      simplex: simplexes[0]
    });

    const wateryPerlin = perlinGroup({
      octaves: 5,
      persistence: 0.6 * topology,
      scale: 0.5,
      simplex: simplexes[1]
    });

    const fertPerlin = perlinGroup({
      octaves: 5,
      persistence: 0.6 * topology,
      scale: 0.5,
      simplex: simplexes[2]
    });

    const dirtPerlin = perlinGroup({
      octaves: 5,
      persistence: 0.6 * topology,
      scale: 0.5,
      simplex: simplexes[3]
    });


    const ROCKYNESS = 0.5 - elevation * elevation * 0.07;
    const ROCKYNESS_FERT = ROCKYNESS - 0.1;

    const FERT = 0.95 - fertility * 1.2 - tropical * 0.2;

    const WETNESS = 0.95 - fertility * 0.3 - tropical * 0.3;
    const WETNESS_SHALLOW = WETNESS - 0.1;
    const WETNESS_FERT = WETNESS_SHALLOW - 0.1;

    this.colorProfile = {
      [STM_BIOMS.SLATE]: [0.25, 0.25, 0.25],
      [STM_BIOMS.GRANITE]: [0.5, 0.5, 0.5],
    }

    this.getColorAt = function(tileX, tileY) {

      const s = 0.01;
      const x = tileX * s;
      const y = tileY * s;

      const rockyness = land ? getPerlin(x, y, rockyPerlin) : -2;
      const BLOCK_HEIGHT = 0.1
      const MAX_BLOCK_HEIGHT = 10 * BLOCK_HEIGHT;

      let height = 0;
      // total = total * total;
      // total = total * 6;
      // total = total * 0.5 + 0.5;
      let color = [0, 0, 0];
      let biom = 0;



      let watery = getPerlin(x, y, wateryPerlin) - rockyness * 0.4
      let waterDepth = 0;
      let hydration = 0;
      let vegetation = 0;

      if (rockyness > ROCKYNESS) {

        biom = STM_BIOMS.SLATE;
        height = Math.min((rockyness - ROCKYNESS) * 1.5 + BLOCK_HEIGHT, MAX_BLOCK_HEIGHT);
        color = [0.25, 0.25, 0.25];

      } else if (rockyness > ROCKYNESS_FERT) {

        biom = STM_BIOMS.GRANITE;
        height = random(tileX, tileY) < 0.025 ? BLOCK_HEIGHT : 0;
        color = [0.5, 0.5, 0.5];

      } else if (icey) {
        let icey = getPerlin(x, y, wateryPerlin);
        if (icey < 0.5) {
          biom = STM_BIOMS.ICE;
          color = [0.9, 0.9, 0.9];
        } else {
          biom = STM_BIOMS.BLUEICE;
          color = [0.75, 0.75, 1];
        }
      } else if (watery > WETNESS) {

        biom = STM_BIOMS.WATER;
        color = [0, tropical * 0.8, 1 - tropical * 0.2];
        waterDepth = -BLOCK_HEIGHT * 2;
        height = -BLOCK_HEIGHT;

      } else if (watery > WETNESS_SHALLOW) {

        biom = STM_BIOMS.WATER;
        color = [0.4 - tropical * 0.2, 0.4 + tropical * 0.2, 0.8 - tropical * 0.2];
        waterDepth = -BLOCK_HEIGHT * 1;
        height = -BLOCK_HEIGHT;

      } else if (watery > WETNESS_FERT) {

        hydration = 1;
        biom = STM_BIOMS.GRASS;
        color = [0.0, 0.5, tropical * 0.2];
        height = 0;

      } else {

        let fert = getPerlin(x, y, fertPerlin) + watery * 0.4;
        let dirt = getPerlin(x, y, dirtPerlin);
        let cold = Math.sqrt(1 - temp) * 0.1;
        const DIRT_LEVEL = 0.2;

        if (fert > FERT) {

          hydration = 1;
          if (dirt > DIRT_LEVEL) {

            biom = STM_BIOMS.DIRT;
            color = [0.4, 0.35, 0.3];

          } else {

            vegetation = 1;
            biom = STM_BIOMS.GRASS;
            color = [0.1, 0.6, 0.1];
            height = (fert - FERT) < 0.3 ? 0 : BLOCK_HEIGHT * 1;

          }

        } else if (fert > FERT - 0.2) {

          hydration = 0.5;
          if (dirt > DIRT_LEVEL) {

            biom = STM_BIOMS.DIRT;
            color = [0.6, 0.55, 0.45];

          } else {

            vegetation = 1;
            biom = STM_BIOMS.GRASS;
            color = [0.3, 0.7, 0.3];
            height = BLOCK_HEIGHT * 1;

          }

        } else {

          height = BLOCK_HEIGHT * 1;
          hydration = 0.2;

          if (dirt > DIRT_LEVEL) {
            biom = STM_BIOMS.DIRT;
            color = [0.8 + cold, 0.7 + cold, 0.55 + cold];
          } else {
            vegetation = 1;
            biom = STM_BIOMS.GRASS;
            color = [0.85 - cold, 0.8 - cold, 0.45 + cold];
          }
        }
      }

      let r255 = Math.max(0, Math.min(Math.round(color[0] * 255)));
      let g255 = Math.max(0, Math.min(Math.round(color[1] * 255)));
      let b255 = Math.max(0, Math.min(Math.round(color[2] * 255)));

      let hsl = rgbToHsl(r255, g255, b255);
      hsl[1] *= temp;
      let rgb = hslToRgb(...hsl);
      rgb.push(height - BLOCK_HEIGHT * 4.5);
      rgb.push(biom);
      return rgb;
    };

  };



}