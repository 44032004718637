import {
  EmpytMesh,
  MeshToAttributeBuffers
} from '@/visual/world/mesh';
import * as THREE from 'three';
import { Vector3, Vector2 } from 'three';

export function iuToWU(unit) {
  return unit / 160;
}

export function getTexureSize(texture) {
  return new Vector2(iuToWU(texture.image.width), iuToWU(texture.image.height));
}

export const FullUvRect = { left: 0, right: 1, top: 0, bottom: 1 };
export const FlipXUvRect = { left: 1, right: 0, top: 0, bottom: 1 };

export function CreateRuberhoseMesh(width = 1) {

  const mesh = EmpytMesh();

  function vertexFrom(x, y, z, u, v) {
    return {
      p: new Vector3(x, y, z),
      t: new Vector2(u, v),
    }
  }

  function vertexUV(u, v) {
    const x = (u - 0.5) * width;
    return vertexFrom(x, v, 0, u, v);
  }

  const resolution = 20;

  for (let j = 0; j <= resolution; j++) {
    const v = j / resolution;
    mesh.vertices.push(vertexUV(0, v));
    mesh.vertices.push(vertexUV(1, v));
  }

  for (let j = 0; j < resolution; j++) {
    const i00 = j * 2 + 0;
    const i10 = j * 2 + 1;
    const i01 = (j + 1) * 2 + 0;
    const i11 = (j + 1) * 2 + 1;

    mesh.indices.push(i00, i10, i11);
    mesh.indices.push(i11, i01, i00);

  }

  // mesh.indices.push(0, 1, 2);
  // mesh.indices.push(2, 3, 0);
  // console.log('mesh.vertices', mesh.vertices)
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(mesh.vertices.flatMap(v => [v.p.x, v.p.y, v.p.z])), 3));
  geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(mesh.vertices.flatMap(v => [v.t.x, v.t.y])), 2));
  geometry.setIndex(new THREE.BufferAttribute(new Uint16Array(mesh.indices), 1));
  return geometry;

}

export function CreateQuadMesh(size, rect = FullUvRect) {
  const DX = new Vector3(1, 0, 0),
    DY = new Vector3(0, 1, 0);

  const unitSizeX = size.x / 2;
  const unitSizeY = size.y / 2;

  const mesh = EmpytMesh();

  function vertexFrom(x, y, z, u, v) {
    return {
      p: new Vector3(x, y, z),
      t: new Vector2(u, v),
    }
  }

  mesh.vertices.push(vertexFrom(
    -unitSizeX * DX.x - unitSizeY * DY.x,
    -unitSizeX * DX.y - unitSizeY * DY.y,
    -unitSizeX * DX.z - unitSizeY * DY.z,
    rect.left, rect.top));

  mesh.vertices.push(vertexFrom(
    +unitSizeX * DX.x - unitSizeY * DY.x,
    +unitSizeX * DX.y - unitSizeY * DY.y,
    +unitSizeX * DX.z - unitSizeY * DY.z,
    rect.right, rect.top));

  mesh.vertices.push(vertexFrom(
    +unitSizeX * DX.x + unitSizeY * DY.x,
    +unitSizeX * DX.y + unitSizeY * DY.y,
    +unitSizeX * DX.z + unitSizeY * DY.z,
    rect.right, rect.bottom));

  mesh.vertices.push(vertexFrom(
    -unitSizeX * DX.x + unitSizeY * DY.x,
    -unitSizeX * DX.y + unitSizeY * DY.y,
    -unitSizeX * DX.z + unitSizeY * DY.z,
    rect.left, rect.bottom));

  mesh.indices.push(0, 1, 2);
  mesh.indices.push(2, 3, 0);
  // console.log('mesh.vertices', mesh.vertices)
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(mesh.vertices.flatMap(v => [v.p.x, v.p.y, v.p.z])), 3));
  geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(mesh.vertices.flatMap(v => [v.t.x, v.t.y])), 2));
  geometry.setIndex(new THREE.BufferAttribute(new Uint16Array(mesh.indices), 1));
  return geometry;

}