export const RubberhoseShaders = {
  vertexShader: `

varying vec2 vUv;

uniform vec3 uWidth;
uniform vec3 uP0;
uniform vec3 uP1;
uniform vec3 uEX0;
uniform vec3 uEX1;

void main() {
  vUv = uv;

  // float dc = vUv.y-0.5;

  // if (abs(dc) < 0.5)
    // vUv.y += -dc * 0.3;

  //vUv.y = vUv.y > 0.0 ? vUv.y*vUv.y : sqrt(vUv.y);

  float t0_1 = position.y;
  float t0_2 = t0_1 * t0_1;
  float t0_3 = t0_1 * t0_1 * t0_1;

  float e0 = 2.0 * t0_3 - 3.0 * t0_2 + 1.0;
  float e1 = t0_3 - 2.0 * t0_2 + t0_1;
  float e2 = t0_3 - t0_2;
  float e3 = -2.0 * t0_3 + 3.0 * t0_2;

  vec3 C = e0 * uP0 + e1 * uEX0 + e2 * uEX1 + e3 * uP1;

  float de0 = 6.0 * t0_2 - 6.0 * t0_1;
  float de1 = 3.0 * t0_2 - 4.0 * t0_1 + 1.0;
  float de2 = 3.0 * t0_2 - 2.0 * t0_1;
  float de3 = -6.0 * t0_2 + 6.0 * t0_1;

  vec3 D = de0 * uP0 + de1 * uEX0 + de2 * uEX1 + de3 * uP1;

  float factor = position.x / length(D.xy) * (uWidth.y*t0_1+uWidth.x*(1.0-t0_1));
  float tx = -D.y * factor;
  float ty = D.x * factor;

  vec3 pos = C + vec3(tx, ty, 0.0);

  gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
}`,
  fragmentShader: `
varying vec2 vUv;
uniform vec3 uColor;
uniform sampler2D uImage;

void main() {
  vec4 color = texture2D(uImage, vUv);
  // vec3 color = vec3(vUv.x, vUv.y, 0.0);
  // color.r += 1.0-color.a;
  // color.r = vUv.y;
  // color.a = 1.0;
  gl_FragColor = color;
}`
}