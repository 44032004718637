function touchHandler(event) {
  let touch = event.changedTouches[0];

  let simulatedEvent = document.createEvent("MouseEvent");
  simulatedEvent.initMouseEvent({
      touchstart: "mousedown",
      touchmove: "mousemove",
      touchend: "mouseup"
    } [event.type], true, true, window, 1,
    touch.screenX, touch.screenY,
    touch.clientX, touch.clientY, false,
    false, false, false, 0, null);

  event.preventDefault();
  touch.target.dispatchEvent(simulatedEvent);
}


function setTouchElement(element) {
  element.addEventListener("touchstart", touchHandler);
  element.addEventListener("touchmove", touchHandler);
  element.addEventListener("touchend", touchHandler, true);
  element.addEventListener("touchcancel", touchHandler, true);
}

export function CreateInputController(element) {
  console.log('CreateInputController');

  this.hasMouseCtx = false;
  this.element = null;

  this.attach = function(cameraController) {
    this.cameraController = cameraController;
    // this.cameraController = this.element;
  };

  this.init = function(element) {

    let that = this;
    this.hasMouseCtx = false;
    this.element = element

    setTouchElement(element);

    element.onclick = function(event) {
      event.preventDefault();
      that.cameraController.click(event);
      return false;
    };

    window.addEventListener("keyup", function(event) {
      // event.preventDefault();
      if (that.cameraController.keyUp)
        that.cameraController.keyUp(event);
    });


    window.addEventListener("keydown", function(event) {
      // event.preventDefault();
      if (that.cameraController.keyDown)
        that.cameraController.keyDown(event);
    });


    element.onmousedown = function(event) {
      event.preventDefault();
      that.cameraController.mouseDown(event);
      return false;
    };

    element.onmouseup = function(event) {
      event.preventDefault();
      that.cameraController.mouseUp(event);
      return false;
    };

    element.onmouseout = function(event) {
      element.onmouseup(event);
      that.hasMouseCtx = false;
    };

    element.onmousemove = function(event) {
      event.preventDefault();
      that.hasMouseCtx = true;
      that.cameraController.mouseMove(event);
    };

    let mouseScroll = function(event) {
      if (!that.hasMouseCtx)
        return;
      event.preventDefault();
      let delta = 0;
      if (!event)
        event = window.event;
      if (event.wheelDelta) {
        delta = event.wheelDelta / 60;
      } else if (event.detail) {
        delta = -event.detail / 2;
      }

      that.cameraController.mouseScroll(delta);

    };

    if (element.addEventListener)
      element.addEventListener('DOMMouseScroll', mouseScroll);
    element.onmousewheel = mouseScroll;
    element.oncontextmenu = function() {
      return false;
    };

  }

  if (element) {
    this.init(element);
  }

}