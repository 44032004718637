import rough from 'roughjs/bin/rough.js';
import {
  createExtrudeFromPath,
  createPathFromCurve,
  createCanvas,
  translate,
} from './draw-utils'

export function createCharacter() {
  const character = {
    roughness: 2,
    strokeWidth: 6,
    centerLine: 80,
    partMargin: 5,
    head: {
      height: 140,
      width: 150
    },
    body: {
      height: 140,
      width: 140,
      shape: 0.7
    },
    faceMargin: { x: 20, y: 20 },
    eye: {
      x: 30,
      y: -17,
      size: 15
    },
    mouth: {
      y: 35,
      size: 20
    },
    arm: {
      width: 8,
      length: 120,
      out: 60,
    },
    leg: {
      width: 8,
      length: 140,
      out: 0,
    }
  };


  return character
}

export function getCharacterParts(character) {

  const { head, body } = character;

  return [{
    name: 'head',
    type: 'rect',
    left: 0,
    top: head.height / 2 + body.height / 2,
    imageFuncs: [createHead],
    delay: 3,
    depth: 0.5,
    depthVector: { x: 0, y: 3 }
  }, {
    name: 'face',
    type: 'rect',
    left: 0,
    top: head.height / 2 + body.height / 2,
    imageFuncs: [createFace, createFaceSide],
    delay: 3,
    depth: 1,
    depthVector: { x: 0, y: 2 }
   }, {
    name: 'body',
    type: 'rect',
    left: 0,
    top: 0,
    imageFuncs: [createBody],
    delay: 1,
    depth: 0,
    depthVector: { x: 0, y: 0 }
  }, {
    name: 'arm_r',
    type: 'rubber',
    p0: [311, 174],
    p1: [382, 291],
    ex0: [376, 182],
    ex1: [392, 225],
    sp0: [344, 174],
    sp1: [416, 291],
    delay: 2,
    depth: -1,
    depthVector: { x: -3, y: 0 }
  }, {
    name: 'arm_l',
    type: 'rubber',
    p0: [201, 172],
    p1: [121, 282],
    ex0: [140, 175],
    ex1: [119, 233],
    sp0: [232, 172],
    sp1: [153, 282],
    delay: 2,
    depth: -1,
    depthVector: { x: 3, y: 0 }
  }, {
    name: 'leg_r',
    type: 'rubber',
    sp0: [150, 50],
    p0: [100, 50],
    ex0: [150, 70],
    p1: [150, 300],
    sp1: [200, 300],
    ex1: [100, 330],
    delay: 0,
    depth: -1,
    depthVector: { x: -3, y: 0 }
  }, {
    name: 'leg_l',
    type: 'rubber',
    p0: [100, 50],
    sp0: [150, 50],
    ex0: [150, 70],
    p1: [150, 300],
    sp1: [200, 300],
    ex1: [100, 330],
    delay: 0,
    depth: -1,
    depthVector: { x: 3, y: 0 }
  }]
}

export function createBody(character) {

  const {
    body,
    centerLine,
    partMargin,
    roughness,
    strokeWidth
  } = character;

  const canvas = createCanvas();
  canvas.width = centerLine * 2;
  canvas.height = partMargin * 2 + body.height;
  const rc = rough.canvas(canvas);

  const { height, width, shape } = body;

  const bodyPoints = [];

  bodyPoints.push(
    [-width * shape * 0.5, height * 0.5],
    [-width * 0.5, -height * 0.5],
    [width * 0.5, -height * 0.5],
    [width * shape * 0.5, height * 0.5],
  );

  bodyPoints.push(bodyPoints[0].slice());

  translate(bodyPoints, centerLine, partMargin + body.height / 2);
  rc.polygon(bodyPoints, {
    roughness: roughness,
    fill: 'rgba(255,255,255,1)',
    fillStyle: 'solid',
    stroke: 'black',
    strokeWidth: strokeWidth
  });


  return canvas;
}


export function createFaceSide(character) {

  const {
    head,
    eye,
    mouth,
    centerLine,
    faceMargin,
    partMargin,
    roughness,
    strokeWidth
  } = character;

  const headLoc = partMargin + head.height / 2;

  const canvas = createCanvas();
  canvas.width = centerLine * 2 - faceMargin.x * 2;
  canvas.height = partMargin * 2 + head.height - faceMargin.y * 2;
  const rc = rough.canvas(canvas);

  let eyePointsR = [
    [-eye.size, eye.y],
    [+eye.size, eye.y]
  ];
  translate(eyePointsR, centerLine + eye.x - faceMargin.x, headLoc - faceMargin.y)
  rc.curve(eyePointsR, {
    roughness: 0.75,
    stroke: 'black',
    strokeWidth,
  });


  let xOffset = 35;

  let mouthPoints = [
    [+mouth.size + xOffset, mouth.y],
    [xOffset, mouth.y + 2],
    [+mouth.size + xOffset, mouth.y]
  ];
  translate(mouthPoints, centerLine - faceMargin.x, headLoc - faceMargin.y)
  rc.curve(mouthPoints, {
    roughness: 0.75,
    stroke: 'black',
    strokeWidth,
  });
  return canvas;

}


export function createFace(character) {

  const {
    head,
    eye,
    mouth,
    centerLine,
    faceMargin,
    partMargin,
    roughness,
    strokeWidth
  } = character;

  const headLoc = partMargin + head.height / 2;

  const canvas = createCanvas();
  canvas.width = centerLine * 2 - faceMargin.x * 2;
  canvas.height = partMargin * 2 + head.height - faceMargin.y * 2;
  const rc = rough.canvas(canvas);

  let eyePointsR = [
    [-eye.size, eye.y],
    [+eye.size, eye.y]
  ];
  translate(eyePointsR, centerLine + eye.x - faceMargin.x, headLoc - faceMargin.y)
  rc.curve(eyePointsR, {
    roughness: 0.75,
    stroke: 'black',
    strokeWidth,
  });

  let eyePointsL = [
    [-eye.size, eye.y],
    [+eye.size, eye.y]
  ];
  translate(eyePointsL, centerLine - eye.x - faceMargin.x, headLoc - faceMargin.y)
  rc.curve(eyePointsL, {
    roughness: 0.75,
    stroke: 'black',
    strokeWidth,
  });


  let mouthPoints = [
    [-mouth.size, mouth.y],
    [0, mouth.y + 2],
    [+mouth.size, mouth.y]
  ];
  translate(mouthPoints, centerLine - faceMargin.x, headLoc - faceMargin.y)
  rc.curve(mouthPoints, {
    roughness: 0.75,
    stroke: 'black',
    strokeWidth,
  });
  return canvas;

}

export function createArmR(character) {

  const { arm, partMargin, roughness, strokeWidth } = character;

  const canvas = createCanvas();
  canvas.width = partMargin * 2 + arm.width + arm.out;
  canvas.height = partMargin * 2 + arm.length;
  const rc = rough.canvas(canvas);

  let cPoints = [
    [0, arm.width / 2,
      [0, 1]
    ],
    [arm.out + arm.width / 2, arm.length,
      [-1, 0]
    ],
  ];


  let points = createExtrudeFromPath(cPoints, arm.width);

  translate(points, partMargin, partMargin);
  rc.polygon(points, {
    roughness: roughness,
    fill: 'rgba(255,255,255,1)',
    fillStyle: 'solid',
    stroke: 'black',
    strokeWidth: strokeWidth
  });
  return canvas;
}

export function createLegRBackward(character) {
  const { leg, partMargin, roughness, strokeWidth } = character;
  const halfSize = (leg.out + leg.length) / 2;

  const canvas = createCanvas();
  canvas.width = partMargin * 2 + leg.width * 2 + halfSize;
  canvas.height = partMargin * 2 + halfSize;
  const rc = rough.canvas(canvas);


  let cPoints = [
        [0, leg.width / 2,
          [0, 1]
        ],
        [halfSize / 2 + leg.width / 2, halfSize,
          [-0.5, -0.5]
        ],
      ];


  let points = createExtrudeFromPath(cPoints, leg.width, 250);

  translate(points, partMargin + leg.width / 2, partMargin);
  rc.polygon(points, {
    roughness: roughness,
    fill: 'rgba(255,255,255,1)',
    fillStyle: 'solid',
    stroke: 'black',
    strokeWidth: strokeWidth
  });
  return canvas;

}

export function createLegRForward(character) {
  const { leg, partMargin, roughness, strokeWidth } = character;
  const halfSize = (leg.out + leg.length) / 2;

  const canvas = createCanvas();
  canvas.width = partMargin * 2 + leg.width * 2 + halfSize;
  canvas.height = partMargin * 2 + halfSize * 1.3;
  const rc = rough.canvas(canvas);


  let cPoints = [
        [0, leg.width / 2,
          [0, 1]
        ],
        [halfSize * 0.8 + leg.width / 2, halfSize * 1.3,
          [-0.9, -0.2]
        ],
      ];


  let points = createExtrudeFromPath(cPoints, leg.width, 290);

  translate(points, partMargin + leg.width / 2, partMargin);
  rc.polygon(points, {
    roughness: roughness,
    fill: 'rgba(255,255,255,1)',
    fillStyle: 'solid',
    stroke: 'black',
    strokeWidth: strokeWidth
  });
  return canvas;

}

export function createLegR(character) {
  const { leg, partMargin, roughness, strokeWidth } = character;

  const canvas = createCanvas();
  canvas.width = partMargin * 2 + leg.width * 2 + leg.out;
  canvas.height = partMargin * 2 + leg.length;
  const rc = rough.canvas(canvas);


  let cPoints = [
        [0, leg.width / 2,
          [-1, 0]
        ],
        [leg.out + leg.width / 2, leg.length,
          [-1, 0]
        ],
      ];


  let points = createExtrudeFromPath(cPoints, leg.width);

  translate(points, partMargin + leg.width / 2, partMargin);
  rc.polygon(points, {
    roughness: roughness,
    fill: 'rgba(255,255,255,1)',
    fillStyle: 'solid',
    stroke: 'black',
    strokeWidth: strokeWidth
  });
  return canvas;

}


export function createHead(character) {

  const { head, centerLine, partMargin, roughness, strokeWidth } = character;

  const headLoc = partMargin + head.height / 2;

  const canvas = createCanvas();
  canvas.width = centerLine * 2;
  canvas.height = partMargin * 2 + head.height;
  const rc = rough.canvas(canvas);

  rc.ellipse(centerLine, headLoc, head.width, head.height, {
    fill: 'rgba(255,255,255,1)',
    fillStyle: 'solid',
    strokeWidth: strokeWidth,
    roughness: roughness * 0.5,
  });


  return canvas;
}