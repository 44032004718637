import * as THREE from 'three';
import { Vector3, Vector2 } from 'three';

import {
  RubberhoseShaders,
} from '@/visual/pawn/shaders-toon';

import {
  interpolateTransform,
  interpolateTransformPoint,
} from '@/visual/pawn/draw-utils';

import {
  createLegR,
} from '@/visual/pawn/character';

import {
  CreateRuberhoseMesh,
  CreateQuadMesh,
  FullUvRect,
  FlipXUvRect,
  iuToWU,
  getTexureSize
} from '@/visual/pawn/utils';


export const OrientationState = {
  0: {
    horizontal: 1,
    orientation: 0,
    order: 1,
    name: '_side'
  },
  1: {
    horizontal: 1,
    orientation: Math.PI / 2,
    order: -1,
    name: ''
  },
  2: {
    horizontal: -1,
    orientation: Math.PI,
    order: 1,
    name: '_side'
  },
  3: {
    horizontal: 1,
    orientation: 3 * Math.PI / 2,
    order: 1,
    name: ''
  }
};

function InitRectPart(part, i, animState) {

    const {
      character,
      character3d,
      centerX,
      centerY,
      minY
    } = animState;

  part.images = part.imageFuncs.flatMap(func => [
    func(character),
    func(character),
  ]);

  const { name, images, left, top, flipX, delay, depth, depthVector } = part;


  const textures = images.map(image => new THREE.CanvasTexture(image))
  const material = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
    alphaTest: 0.1,
    blending: THREE.CustomBlending,
    blendSrc: THREE.OneFactor,
    blendDst: THREE.OneMinusSrcAlphaFactor,
    map: textures[0]
  });

  material.textures = textures;

  const rect = flipX ? FlipXUvRect : FullUvRect;
  const size = getTexureSize(textures[0]);
  const geom = CreateQuadMesh(size, rect);
  const plane = new THREE.Mesh(geom, material);
  plane.offsetY = -minY;
  plane.position.y = iuToWU(top) + plane.offsetY;
  plane.position.x = iuToWU(left);

  character3d.add(plane);

  animState.objs3d[name] = plane;

  part.update = glitchFrame => {

  };

  part.animate = (relativeTime, frameA, frameB, t) => {

    const {
      orientation,
      horizontal
    } = animState

    const zPos = depth + Math.cos(orientation) * depthVector.x + Math.sin(orientation) * depthVector.y;
    // console.log("zPos", name, zPos)
    plane.position.z = zPos / 200;


    let interp = frameB ? interpolateTransform(frameA, frameB, t) : frameA;
    let imageIndex = frameA.imageIndex || 0;
    let glitchFrame = parseInt(relativeTime / 2) % 2 + imageIndex * 2;

    const obj3d = animState.objs3d[name];
    const relativeScaleX = images[0].width / images[glitchFrame].width
    const relativeScaleY = images[0].height / images[glitchFrame].height

    obj3d.position.y = iuToWU(-interp.top + centerY) + obj3d.offsetY;
    obj3d.position.x = horizontal * iuToWU(interp.left - centerX);
    obj3d.rotation.z = -horizontal*interp.angle * Math.PI / 180;
    obj3d.scale.x = horizontal * interp.scaleX / relativeScaleX;
    obj3d.scale.y = interp.scaleY / relativeScaleY;
    obj3d.material.map = obj3d.material.textures[glitchFrame]

  };

}


let rubberImages;
const rubberhoseGeom = CreateRuberhoseMesh();

function InitRubberPart(part, i, animState) {


  const {
    character,
    character3d,
    centerX,
    centerY,
    minY
  } = animState;

  if (!rubberImages) {
    rubberImages = [createLegR(character), createLegR(character)]
      .map(image => new THREE.CanvasTexture(image));
  }

  const { arm, partMargin } = character;
  const { name, p0, p1, ex0, ex1, sp0, sp1, depth, depthVector } = part;

  const material = new THREE.ShaderMaterial({
    ...RubberhoseShaders,
    side: THREE.DoubleSide,
    // depthWrite: false,
    transparent: true,
    alphaTest: 0.2,
    blending: THREE.CustomBlending,
    blendSrc: THREE.OneFactor,
    blendDst: THREE.OneMinusSrcAlphaFactor,
    uniforms: {
      uWidth: { value: new Vector3(1, 1, 1) },
      uP0: { value: new Vector3(0, 0, 0) },
      uEX0: { value: new Vector3(0, 0, 0) },
      uEX1: { value: new Vector3(0, 0, 0) },
      uP1: { value: new Vector3(0, 0, 0.03) },
      uColor: { value: new Vector3(1, 0, 0) },
      uImage: { value: rubberImages[0] }
    }
  });

  const amount = 2;
  const rubberhoseMesh = new THREE.Mesh(rubberhoseGeom, material);
  character3d.add(rubberhoseMesh);
  // rubberhoseMesh.position.z = 0.01;
  // rubberhoseMesh.position.z = depth / 200;

  part.material = material;
  part.mesh = rubberhoseMesh;
  part.updateUniforms = (glitchFrame) => {

    const {
      orientation,
      horizontal
    } = animState

    const zPos = depth + Math.cos(orientation) * depthVector.x + Math.sin(orientation) * depthVector.y;
    rubberhoseMesh.position.z = zPos / 200;

    const unis = material.uniforms;
    unis.uP0.value.x = horizontal * iuToWU(p0[0] - centerX)
    unis.uP0.value.y = -iuToWU(p0[1] - centerY) - minY
    unis.uEX0.value.x = horizontal * iuToWU(ex0[0] - p0[0]) * amount
    unis.uEX0.value.y = -iuToWU(ex0[1] - p0[1]) * amount
    unis.uP1.value.x = horizontal * iuToWU(p1[0] - centerX)
    unis.uP1.value.y = -iuToWU(p1[1] - centerY) - minY
    unis.uP1.value.z = -Math.sin(orientation) * 0.03;
    unis.uEX1.value.x = -horizontal * iuToWU(ex1[0] - p1[0]) * amount
    unis.uEX1.value.y = iuToWU(ex1[1] - p1[1]) * amount

    const wscale0 = (Math.abs(p0[0] - sp0[0]) / 30)
    const wscale1 = (Math.abs(p1[0] - sp1[0]) / 30)

    unis.uWidth.value.x = iuToWU(arm.width * 2 + partMargin * 2) * wscale0;
    unis.uWidth.value.y = iuToWU(arm.width * 2 + partMargin * 2) * wscale1;

    unis.uImage.value = rubberImages[glitchFrame || 0];

  };

  part.animate = (relativeTime, frameA, frameB, t) => {
    frameB = frameB || []

    interpolateTransformPoint(p0, frameA[0], frameB[0], t)
    interpolateTransformPoint(p1, frameA[1], frameB[1], t)
    interpolateTransformPoint(ex0, frameA[2], frameB[2], t)
    interpolateTransformPoint(ex1, frameA[3], frameB[3], t)
    interpolateTransformPoint(sp0, frameA[4], frameB[4], t)
    interpolateTransformPoint(sp1, frameA[5], frameB[5], t)


    let glitchFrame = parseInt(relativeTime / 2) % 2;
    part.updateUniforms(glitchFrame);

  };

}

export const partGenerators = {
  'rect': InitRectPart,
  'rubber': InitRubberPart,
};
