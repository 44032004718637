import { defineComponent, ref, computed, watch } from 'vue';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { GenerateHead } from '@/visual/pawn/head';


const METER_TO_WORLD = 0.1;

const geometry = new THREE.PlaneGeometry(1, 1);
const array = geometry.attributes.position.array;
const TILE_SIZE = 0.1;
for (let i = 0; i < array.length; i += 3) {
  array[i + 0] = array[i + 0] * TILE_SIZE * 2 / METER_TO_WORLD
  array[i + 2] = -array[i + 1] * TILE_SIZE * 2 / METER_TO_WORLD
  array[i + 1] = 0.01;
}

const loader = new THREE.TextureLoader();
const texture = loader.load('../img/shadow.png');
// console.log('texture', texture)

const material = new THREE.MeshBasicMaterial({
  map: texture,
  color: 0x555555,
  transparent: true,
  blending: THREE.SubtractiveBlending,
});

function createDropShadow() {
  const plane = new THREE.Mesh(geometry, material);
  plane.castShadow = false;
  plane.receiveShadow = false;
  return plane;

}


const bodyMaterial = new THREE.MeshLambertMaterial({
  // depthWrite: false,
  color: 0x334455
});

function superelipsiodish(geometry, radius, power) {
  const array = geometry.attributes.position.array;
  for (let i = 0; i < array.length; i += 3) {
    const zr = 1;
    // Math.min(1, Math.abs(array[i + 2]) + 0.1);
    // zr = Math.cos(zr * Math.PI / 4);
    array[i] = Math.pow(Math.abs(array[i] / zr), power) * Math.sign(array[i]) * zr * radius.x * 0.5;
    array[i + 1] = Math.pow(Math.abs(array[i + 1] / zr), power) * Math.sign(array[i + 1]) * zr * radius.y * 0.5;
    array[i + 2] = Math.pow(Math.abs(array[i + 2] / zr), power) * Math.sign(array[i + 2]) * zr * radius.z * 0.5;
  }
  geometry.attributes.position.needsUpdate = true;
}

function taperY(geometry, height,
  taperAmount1, taperAmount2 = 1, taperAmount3 = -0.75,
  taperCurve = 1
) {
  const array = geometry.attributes.position.array;
  for (let i = 0; i < array.length; i += 3) {
    let t = (array[i + 1] / height + 0.5);

    // t = t * t; //Math.sqrt(t);
    t = Math.pow(t, taperCurve);

    const val =
      taperAmount1 * t +
      taperAmount2 * (1 - t) +
      taperAmount3 * (t) * (1 - t);

    array[i + 0] *= val;
    array[i + 2] *= val;
  }
  geometry.attributes.position.needsUpdate = true;
}

function translate(geometry, vector) {
  const array = geometry.attributes.position.array;
  for (let i = 0; i < array.length; i += 3) {
    array[i + 0] += vector.x;
    array[i + 1] += vector.y;
    array[i + 2] += vector.z;
  }
  geometry.attributes.position.needsUpdate = true;
}


export function GenerateBody() {

  const head = GenerateHead();

  const neckThickness = ref(0.15);
  const neckHeight = ref(0.25);
  const torsoSize = ref(new Vector3(1.0, 1.5, 0.5));
  const armSize = ref(new Vector3(0.4, 0.1, 1.5));
  const legSize = ref(new Vector3(0.4, 0.1, 1.5));

  const totalHeight = torsoSize.value.y + legSize.value.z + neckHeight.value + 1;
  // console.log("totalHeight", totalHeight)

  const FEET_TO_METERS = 0.3048;

  const pawn = new THREE.Object3D();
  const pawnCont = new THREE.Object3D();
  pawn.scale.set(FEET_TO_METERS, FEET_TO_METERS, FEET_TO_METERS)

  const headYOffset = 0.9 + neckHeight.value + torsoSize.value.y;
  head.node.position.y = headYOffset;
  pawn.add(head.node);

  const neckGeometry = new THREE.CylinderGeometry(
    neckThickness.value,
    neckThickness.value,
    neckHeight.value, 12, 1, true);
  const neck = new THREE.Mesh(neckGeometry, bodyMaterial);
  neck.position.y = neckHeight.value / 2 + torsoSize.value.y;
  pawn.add(neck);

  const torsoGeom = new THREE.SphereGeometry(1, 16, 32);
  superelipsiodish(torsoGeom, torsoSize.value, 0.15);
  taperY(torsoGeom, torsoSize.value.y, 1.2);
  const torso = new THREE.Mesh(torsoGeom, bodyMaterial);
  torso.position.y = torsoSize.value.y / 2;
  pawn.add(torso);

  const armGeometry = new THREE.CylinderGeometry(armSize.value.x / 2, armSize.value.y / 2, armSize.value.z, 12, 1, false);
  translate(armGeometry, new Vector3(0, -armSize.value.z / 2, 0));

  const leftArm = new THREE.Mesh(armGeometry, bodyMaterial);
  const rightArm = new THREE.Mesh(armGeometry, bodyMaterial);

  const shoulderYOffset = -armSize.value.x / 2 + torsoSize.value.y;

  leftArm.position.y = rightArm.position.y = shoulderYOffset;
  leftArm.position.x = -(torsoSize.value.x / 2)
  leftArm.rotation.z = -Math.PI / 10;
  rightArm.position.x = -leftArm.position.x
  rightArm.rotation.z = -leftArm.rotation.z

  pawn.add(leftArm);
  pawn.add(rightArm);

  const legGeometry = new THREE.CylinderGeometry(legSize.value.x / 2, legSize.value.y / 2, legSize.value.z, 12, 1, false);
  translate(legGeometry, new Vector3(0, -legSize.value.z / 2, 0));

  const leftLeg = new THREE.Mesh(legGeometry, bodyMaterial);
  const rightLeg = new THREE.Mesh(legGeometry, bodyMaterial);
  leftLeg.position.y = rightLeg.position.y = 0.1;
  leftLeg.position.x = -(torsoSize.value.x / 2 - legSize.value.x / 2)
  leftLeg.rotation.z = -0.1
  rightLeg.position.x = -leftLeg.position.x
  rightLeg.rotation.z = -leftLeg.rotation.z
  pawn.add(leftLeg);
  pawn.add(rightLeg);

  rightLeg.castShadow = true;
  leftLeg.castShadow = true;
  rightArm.castShadow = true;
  leftArm.castShadow = true;
  torso.castShadow = true;
  neck.castShadow = true;

  // pawnObj.time = Math.random() * 100;

  function update() {
    pawnObj.time += 0.01666;

    const time = pawnObj.time;

    head.properties.irisLook.value = Math.sin(time + Math.sin(time)) * 0.1;
    head.update();

    let t = Math.min((Math.sin(time * 0.5) + 1) * 80, 1);
    head.properties.eyeState.x = t * 0.3;

    const walking = 1;

    const rate = 16;
    const stride = 0.5 * walking;
    rightLeg.rotation.x = Math.sin(time * rate) * stride;
    leftLeg.rotation.x = -Math.sin(time * rate) * stride;
    pawn.position.y =
      (-Math.cos(time * rate * 2) * stride * 0.25 + torsoSize.value.y * 0 + legSize.value.z) * FEET_TO_METERS;

    const bob = -Math.sin(time * rate * 2) * stride * 0.05;

    head.node.position.y = bob + headYOffset;

    leftArm.rotation.x = rightLeg.rotation.x * 0.5
    rightArm.rotation.x = leftLeg.rotation.x * 0.5

    leftArm.position.y = rightArm.position.y = shoulderYOffset + bob * 2;

    // pawn.position.y = -1;

  }



  pawnCont.add(createDropShadow());

  pawnCont.add(pawn);


  const pawnObj = {
    node: pawnCont,
    time: 0,
    head,
    update,
    properties: {
      neckThickness,
      neckHeight,
      torsoSize,
      armSize,
      legSize,
    },
    watchers: {}
  };

  update();

  return pawnObj;

}