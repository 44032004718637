import * as THREE from 'three';
import { GenerateChunk } from '@/voxel/voxel-generator';
import { VoxelCoverShaders } from '@/voxel/voxel-shader-cover';
import { VoxelShaders } from '@/voxel/voxel-shader';
import { VoxelShadersWater } from '@/voxel/voxel-shader-water';
import {
  MeshBuffersToThreeJs,
} from '@/visual/world/mesh';
const loader2d = new THREE.TextureLoader();

const dirtTexture = loader2d.load('/img/textures/dirt5.jpg');
const grassTexture = loader2d.load('/img/textures/grass3.jpg');

grassTexture.wrapS = grassTexture.wrapT = THREE.RepeatWrapping;
dirtTexture.wrapS = dirtTexture.wrapT = THREE.RepeatWrapping;

const loadTexture = file => {
  const texture = loader2d.load(file);
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  return texture;
}


export const waterMaterial = new THREE.ShaderMaterial({
  ...VoxelShadersWater,
  vertexColors: true,
  uniforms: {
    uTexture1: { value: loadTexture('/img/textures/water2.jpg') },
    uTexture2: { value: loadTexture('/img/textures/water4.jpg') },
    uTime: { value: 0 }
  }
});

const materialTexture = (file, scale) => {

  const texture = loader2d.load(file);
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

  return new THREE.ShaderMaterial({
    ...VoxelShaders,
    vertexColors: true,
    uniforms: {
      uScale: { value: new THREE.Vector2(...(scale || [1, 1])) },
      uTexture: { value: texture }
    }
  });
}


export const materialCover = new THREE.ShaderMaterial({
  ...VoxelCoverShaders,
  side: THREE.DoubleSide,
  alphaTest: 0.5,
  vertexColors: true,
  // transparent: true,
  // vertexAlphas: true,
  // blending: THREE.CustomBlending,
  // blendSrc: THREE.SrcAlphaFactor,
  // blendDst: THREE.OneMinusSrcAlphaFactor,
  uniforms: {
    uTexture: { value: grassTexture },
    uTime: { value: 0 }

  }
});


let __voxelMap = null;
let total = 0;

const materials = {
  1: materialTexture('/img/textures/grass3.jpg'),
  '1-side': materialTexture('/img/textures/dirt5.jpg', [14, 14]),
  2: materialTexture('/img/textures/dirt5.jpg', [1, 1]),
  3: waterMaterial,
  4: materialTexture('/img/textures/rock7.jpg', [1, 1]),
  5: materialTexture('/img/textures/rock3.jpg', [1, 1]),
  cover: materialCover
}

export const ChunksByIndex = {};
export const ChunksObjsByIndex = {};

export function RegenerateChunk(chunkIndex) {
  DoWork(__voxelMap.chunks[chunkIndex]);
}

function WorkerCallback(event) {

  const {
    bufferMesh,
    bufferMeshes,
    bufferCoverMesh,
    chunkIndex,
  } = event.data;

  const chunk = __voxelMap.chunks[chunkIndex];
  chunk.config.filled = true;

  const meshes = bufferMeshes.map(bufferMesh => {
    const geometry = MeshBuffersToThreeJs(bufferMesh);
    return new THREE.Mesh(geometry, materials[bufferMesh.material]);
  });

  // const geometry = MeshBuffersToThreeJs(bufferMesh);
  // const mesh = new THREE.Mesh(geometry, material);
  //
  // const geometryCover = MeshBuffersToThreeJs(bufferCoverMesh);
  // const meshCover = new THREE.Mesh(geometryCover, materialCover);


  if (chunk.meshes) {
    chunk.meshes.forEach(mesh => __voxelMap.node.remove(mesh))
  }

  meshes.forEach(mesh => __voxelMap.node.add(mesh))
  chunk.meshes = meshes;

}

const workers = [];
let workerCurIndex = 0;

function addWorker() {
  const worker = new Worker('@/voxel/voxel.worker.js', { type: 'module' });
  worker.onmessage = WorkerCallback
  workers.push(worker);
}

for (let i = 0; i < 1; i++)
  addWorker();

function DoWork(voxelChunk) {
  workers[(workerCurIndex++) % workers.length].postMessage({
    voxelChunkConfig: voxelChunk.config
  });
}

export function GenerateChunks(voxelMap) {

  const {
    geoIndex,
    generator,
    worldTileCount,
    chunkTileCount,
    worldSize
  } = voxelMap;

  __voxelMap = voxelMap;
  const chunkCount = worldTileCount / chunkTileCount;

  const list = []
  for (let i = 0; i < chunkCount; i++) {
    for (let j = 0; j < chunkCount; j++) {
      list.push([i, j]);
    }
  }
  list.sort((a, b) => {
    let xa = a[0] - chunkCount / 2;
    let ya = a[1] - chunkCount / 2;
    let xb = b[0] - chunkCount / 2;
    let yb = b[1] - chunkCount / 2;
    return (xa * xa + ya * ya) - (xb * xb + yb * yb);
  });

  list.forEach(([i, j]) => DoWork(voxelMap.chunks[i + j * chunkCount]));
}