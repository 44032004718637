export const WORLD_CONFIG = {
  GENERATOR: 'earthlike',
  WORLD_RADIUS: 2,
  ICE_RANGE: 0.2,
  TROPIC_RANGE: 0.2,
  LAND_CUTOFF: 0.65,
  ROCKY_CUTOFF: 0.55,
  NOISE_SEED: 11,
  WORLD_RESOLUTION: 2,
  COLOR_GROUP_LAND1: [
    { color: 'rgb(270, 250, 130)', pos: 0 },

    { color: 'rgb(40, 200, 40)', pos: 0.35 },
    { color: 'rgb(80, 200, 40)', pos: 0.45 },
    { color: 'rgb(73, 70, 70)', pos: 0.5 },
    { color: 'rgb(50, 50, 50)', pos: 0.65 },
  ],
  COLOR_GROUP_LAND2: [
    { color: 'rgb(70, 50, 30)', pos: 0 },
    { color: 'rgb(20, 100, 20)', pos: 0.35 },
    { color: 'rgb(40, 100, 20)', pos: 0.45 },
    { color: 'rgb(173, 140, 120)', pos: 0.6 },
    { color: 'rgb(73, 70, 70)', pos: 0.75 }
  ],
  COLOR_GROUP_LAND3: [

    { color: 'rgb(10, 200, 10)', pos: 0.0 },
    { color: 'rgb(0, 100, 0)', pos: 0.1 },
    { color: 'rgb(210, 180, 130)', pos: 0.2 },
    { color: 'rgb(250, 210, 130)', pos: 0.6 },
  ],
  COLOR_GROUP_ICEY: [
    { color: 'rgb(140, 140, 190)', pos: 0.0 },
    { color: 'rgb(51, 51, 51)', pos: 0.3 },
  ]
};