export function animatePart(frame, track, animateFunc) {

  const relativeTime = frame + (track.delay || 0) * 0.0;


  let keys = Object.keys(track.keys);
  if (keys.length == 1) {

    animateFunc(relativeTime, track.keys[keys[0]]);

  } else if (keys.length > 1) {
    keys = keys.map(a => parseInt(a)).sort((a, b) => (a - b));
    let forwardKeyframeIndex = 0;
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] > relativeTime) {
        forwardKeyframeIndex = i;
        break;
      }
    }

    let key0 = keys[(forwardKeyframeIndex + keys.length - 1) % keys.length]
    let key1 = keys[forwardKeyframeIndex];


    let t = (relativeTime - key0) / (key1 - key0);
    if (key1 < key0) {
      t = (relativeTime - key0) / (16 - key0);
    }
    animateFunc(relativeTime, track.keys[key0], track.keys[key1], t);
  }


}