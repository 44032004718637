export const VoxelShaders = {
  vertexShader: `

varying vec3 vColor;
varying vec2 vUV;

void main() {
  vUV = uv;
  vColor = color;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position.xyz, 1.);
}`,
  fragmentShader: `
  varying vec2 vUV;
  varying vec3 vColor;
  uniform sampler2D uTexture;
  uniform vec2 uScale;

  vec4 hash4( vec2 p ) { return fract(sin(vec4( 1.0+dot(p,vec2(37.0,17.0)),
                                                2.0+dot(p,vec2(11.0,47.0)),
                                                3.0+dot(p,vec2(41.0,29.0)),
                                                4.0+dot(p,vec2(23.0,31.0))))*103.0); }
  vec4 textureNoTile( sampler2D samp, in vec2 uv )
  {
      vec2 p = floor( uv );
      vec2 f = fract( uv );

      // derivatives (for correct mipmapping)
      vec2 ddx = dFdx( uv );
      vec2 ddy = dFdy( uv );

      // voronoi contribution
      vec4 va = vec4( 0.0 );
      float wt = 0.0;
      for( int j=-1; j<=1; j++ )
      for( int i=-1; i<=1; i++ )
      {
          vec2 g = vec2( float(i), float(j) );
          vec4 o = hash4( p + g );
          vec2 r = g - f + o.xy;
          float d = dot(r,r);
          float w = exp(-5.0*d );
          vec4 c = textureGrad( samp, uv + o.zw, ddx, ddy );
          va += w*c;
          wt += w;
      }

      // normalization
      return va/wt;
  }

void main() {
  vec4 color = textureNoTile(uTexture, vUV*uScale);
  gl_FragColor = vec4(color.rgb*vColor,1.0);

}`
}