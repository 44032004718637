import * as THREE from 'three';
import { Vector3 } from 'three';
import {
  EmpytMesh,
  MeshToAttributeBuffers,
} from '@/visual/world/mesh';

import { SettlementGenerator } from '@/visual/settlement/earthlike-settlement';

const settlementGenerator = new SettlementGenerator();

const VERTICAL_CHUNK_SIZE = 20;

export function GenerateChunk({
  geoIndex,
  gridX,
  gridY,
  chunkTileCount,
  worldSize,
  worldTileCount,
  cells,
  cellsColor,
}) {


  const mesh = EmpytMesh();
  const gridIndex = gridY * worldTileCount + gridX;

  const chunkTileCountMargin = chunkTileCount + 2;
  const gridWorldSize = worldSize / (worldTileCount / chunkTileCount);

  const worldX = (gridX / (worldTileCount - 1) - 0.5) * worldSize;
  const worldy = (gridY / (worldTileCount - 1) - 0.5) * worldSize;

  const tileToWorldUnit = worldSize / worldTileCount;

  const bytesInFloat32 = 4;

  if (!cells) {
    settlementGenerator.setIndex(geoIndex);

    cells = new Uint8Array(
      new SharedArrayBuffer(chunkTileCountMargin * chunkTileCountMargin * VERTICAL_CHUNK_SIZE));
    cellsColor = new Float32Array(
      new SharedArrayBuffer(chunkTileCountMargin * chunkTileCountMargin * 3 * bytesInFloat32));

    cells.fill(0);
    cellsColor.fill(0);


    for (let j = 0; j < chunkTileCountMargin; j++) {
      for (let i = 0; i < chunkTileCountMargin; i++) {
        const tileX = gridX + i;
        const tileY = gridY + j;
        const rgb = settlementGenerator.getColorAt(tileX, tileY);

        const tileHIndex = i + j * chunkTileCountMargin;

        const h = rgb[3]; // rgb[0] + rgb[1] + rgb[2] < 100 ? 1 : 0;
        const biom = rgb[4];

        cellsColor[tileHIndex * 3 + 0] = rgb[0] / 255;
        cellsColor[tileHIndex * 3 + 1] = rgb[1] / 255;
        cellsColor[tileHIndex * 3 + 2] = rgb[2] / 255;

        for (let k = 0; k < VERTICAL_CHUNK_SIZE; k++) {
          const tileIndex3 = tileHIndex * VERTICAL_CHUNK_SIZE + k;
          const y = (k - VERTICAL_CHUNK_SIZE / 2) * tileToWorldUnit;
          let value = 0;
          if (h == y)
            value = biom;
          else if (y < h)
            value = 1;

          cells[tileIndex3] = value;
        }

      }
    }

  }


  function getValueAt(i, j, k) {
    const y = (k - VERTICAL_CHUNK_SIZE / 2) * tileToWorldUnit;
    const tileHIndex = i + j * chunkTileCountMargin;
    const tileIndex3 = tileHIndex * VERTICAL_CHUNK_SIZE + k;
    return cells[tileIndex3] ? 1 : 0;
  }

  function getValueAtWorld(xf, yf, zf) {
    const i = (xf / worldSize + 0.5) * (worldTileCount - 1) - gridX;
    const j = (yf / worldSize + 0.5) * (worldTileCount - 1) - gridY;
    const k = (zf / tileToWorldUnit) + VERTICAL_CHUNK_SIZE / 2;
    return getValueAt(Math.round(i), Math.round(j), Math.round(k));
  }


  function getOcclusionAt(xf, yf, zf) {
    const look = tileToWorldUnit / 2;
    let total = 0;
    total += getValueAtWorld(xf + look, yf - look, zf - look);
    total += getValueAtWorld(xf + look, yf + look, zf - look);
    total += getValueAtWorld(xf + look, yf + look, zf + look);
    total += getValueAtWorld(xf + look, yf - look, zf + look);
    total += getValueAtWorld(xf - look, yf - look, zf - look);
    total += getValueAtWorld(xf - look, yf + look, zf - look);
    total += getValueAtWorld(xf - look, yf + look, zf + look);
    total += getValueAtWorld(xf - look, yf - look, zf + look);
    return 1 - Math.max(0, total - 4) / 4;
  }

  function vertexFrom(x, y, z, c) {
    let occ = getOcclusionAt(x, y, z);
    const sc = 1 - 1 / 400;
    return {
      p: new Vector3(x * sc, z, y * sc),
      c: [c[0] * occ, c[1] * occ, c[2] * occ],
      o: occ,
    }
  }

  const PX = new Vector3(1, 0, 0);
  const PY = new Vector3(0, 1, 0);
  const PZ = new Vector3(0, 0, 1);
  const NX = new Vector3(-1, 0, 0);
  const NY = new Vector3(0, -1, 0);
  const NZ = new Vector3(0, 0, -1);

  const OVERFLOW = 1.01;
  const unitSize = tileToWorldUnit * OVERFLOW / 2;

  function pushQuad(xf, yf, zf, color, DX, DY, DZ) {
    const vc = mesh.vertices.length;

    mesh.vertices.push(vertexFrom(
      xf - unitSize * DX.x - unitSize * DY.x - unitSize * DZ.x,
      yf - unitSize * DX.y - unitSize * DY.y - unitSize * DZ.y,
      zf - unitSize * DX.z - unitSize * DY.z - unitSize * DZ.z,
      color));

    mesh.vertices.push(vertexFrom(
      xf + unitSize * DX.x - unitSize * DY.x - unitSize * DZ.x,
      yf + unitSize * DX.y - unitSize * DY.y - unitSize * DZ.y,
      zf + unitSize * DX.z - unitSize * DY.z - unitSize * DZ.z,
      color));

    mesh.vertices.push(vertexFrom(
      xf + unitSize * DX.x + unitSize * DY.x - unitSize * DZ.x,
      yf + unitSize * DX.y + unitSize * DY.y - unitSize * DZ.y,
      zf + unitSize * DX.z + unitSize * DY.z - unitSize * DZ.z,
      color));

    mesh.vertices.push(vertexFrom(
      xf - unitSize * DX.x + unitSize * DY.x - unitSize * DZ.x,
      yf - unitSize * DX.y + unitSize * DY.y - unitSize * DZ.y,
      zf - unitSize * DX.z + unitSize * DY.z - unitSize * DZ.z,
      color));



    const i00 = vc + 0,
      i01 = vc + 1,
      i11 = vc + 2,
      i10 = vc + 3;

    const swapTris = mesh.vertices[i01].o == mesh.vertices[i10].o;
    if (swapTris) {
      mesh.indices.push(i01, i11, i10);
      mesh.indices.push(i10, i00, i01);
    } else {
      mesh.indices.push(i00, i01, i11);
      mesh.indices.push(i11, i10, i00);
    }


  }

  for (let j = 1; j < chunkTileCount + 1; j++) {
    for (let i = 1; i < chunkTileCount + 1; i++) {
      for (let k = 1; k < VERTICAL_CHUNK_SIZE - 1; k++) {

        const xf = ((i + gridX) / (worldTileCount - 1) - 0.5) * worldSize;
        const yf = ((j + gridY) / (worldTileCount - 1) - 0.5) * worldSize;
        const zf = (k - VERTICAL_CHUNK_SIZE / 2) * tileToWorldUnit;

        const tileHIndex = i + j * chunkTileCountMargin;
        // const yf = worldy + (j / (worldTileCount - 1) - 0.5) * gridWorldSize;
        // const xf = worldX + (i / (worldTileCount - 1) - 0.5) * gridWorldSize;
        const color = [
                  cellsColor[tileHIndex * 3 + 0],
                  cellsColor[tileHIndex * 3 + 1],
                  cellsColor[tileHIndex * 3 + 2]
                ];

        const val = getValueAt(i, j, k);
        if (val) {

          if (!getValueAt(i, j, k - 1)) {
            pushQuad(xf, yf, zf, color, PX, PY, PZ)
          }

          if (!getValueAt(i - 1, j, k)) {
            pushQuad(xf, yf, zf, color, PY, PZ, PX)
          }

          if (!getValueAt(i, j - 1, k)) {
            pushQuad(xf, yf, zf, color, PZ, PX, PY)
          }

          if (!getValueAt(i, j, k + 1)) {
            pushQuad(
              xf,
              yf,
              zf, color, NY, NX, NZ)
          }

          if (!getValueAt(i + 1, j, k)) {
            pushQuad(
              xf,
              yf,
              zf, color, NZ, NY, NX)
          }

          if (!getValueAt(i, j + 1, k)) {
            pushQuad(
              xf,
              yf,
              zf, color, NX, NZ, NY)
          }


        }

      }
    }
  }

  const bufferMesh = MeshToAttributeBuffers(mesh)
  return {
    geoIndex,
    gridX,
    gridY,
    chunkTileCount,
    worldSize,
    worldTileCount,
    bufferMesh,
    cells,
    cellsColor,
    gridIndex
  };

}


//GENERATE PLANE
//
// for (let j = 0; j < count; j++) {
//     for (let i = 0; i < count; i++) {
//         const xf = i / (count - 1);
//         const yf = j / (count - 1);
//
//         const x = worldX + (xf - 0.5) * gridWorldSize * OVERFLOW;
//         const y = worldy + (yf - 0.5) * gridWorldSize * OVERFLOW;
//
//         const tileX = gridX + xf * chunkTileCount;
//         const tileY = gridY + yf * chunkTileCount;
//         const rgb = settlementGenerator.getColorAt(tileX, tileY)
//
//         const h = rgb[0] + rgb[1] + rgb[2] < 100 ? 0.3 : 0; // Math.random() * 0.5;
//
//         mesh.vertices.push({
//             p: new Vector3(x, h, y),
//             c: [rgb[0] / 255, rgb[1] / 255, rgb[2] / 255]
//         });
//     }
// }
//
// for (let j = 0; j < count - 1; j++) {
//     for (let i = 0; i < count - 1; i++) {
//         const i00 = j * count + i + 1,
//             i01 = j * count + i,
//             i11 = (j + 1) * count + i,
//             i10 = (j + 1) * count + i + 1;
//         mesh.indices.push(i00, i01, i11);
//         mesh.indices.push(i11, i10, i00);
//     }
// }