export default function(indexInfo) {
    let { color, geology, surfaceType } = indexInfo;
    let { temp, shoreline } = geology;

    let tempSqrt = Math.sqrt(temp);

    let temperatureRange = Math.pow(1 - Math.abs(0.5 - Math.pow(temp, 0.75)) * 2, 3) * 55 + 10;

    let eleTemp = Math.max(0, shoreline * 40);
    let t0 = -60 - eleTemp * 0.25;
    let t1 = 40 - eleTemp;
    let temperatureMean = tempSqrt * (t1 - t0) + t0;

    let elevation = shoreline * 10;

    let greenness = color[1] / (color[0] + color[1] + color[2]);
    greenness = Math.max(0, greenness - 0.32) * 4;

    let mountainess = elevation > 3.3;
    let buildable = !mountainess && surfaceType != 0;

    return {
        mountainess,
        buildable,
        temperatureMean,
        temperatureRange,
        elevation,
        tropical: Math.pow(temp, 4),
        fertility: greenness
    };
}