import perlinNoise3d from 'perlin-noise-3d';
import tinygradient from 'tinygradient';
import { SURFACETYPE } from './types';

function colorLerp(col1, col2, t) {
    return col1.map((c1, index) => (
        c1 * t + col2[index] * (1 - t)
    ))
}

function colorRGBToArray(col) {
    return [col._r / 255, col._g / 255, col._b / 255]
}

export default function(WORLD_CONFIG) {

    const {
        WORLD_RADIUS,
        ICE_RANGE,
        TROPIC_RANGE,
        LAND_CUTOFF,
        ROCKY_CUTOFF,
        NOISE_SEED,
        WORLD_RESOLUTION,
        COLOR_GROUP_LAND1,
        COLOR_GROUP_LAND2,
        COLOR_GROUP_LAND3,
        COLOR_GROUP_ICEY
    } = WORLD_CONFIG;


    const gradientLand1 = tinygradient(COLOR_GROUP_LAND1);
    const gradientLand2 = tinygradient(COLOR_GROUP_LAND2);
    const gradientLand4 = tinygradient(COLOR_GROUP_LAND3);
    const gradientLand3 = tinygradient(COLOR_GROUP_ICEY);

    const noiseSeeds = [NOISE_SEED, NOISE_SEED, NOISE_SEED, NOISE_SEED];

    const noiseTerrain = new perlinNoise3d();
    const noiseTemp = new perlinNoise3d();
    const noiseLand = new perlinNoise3d();
    const noiseLand2 = new perlinNoise3d();
    const noiseElevation2 = new perlinNoise3d();
    const noiseIce = new perlinNoise3d();

    noiseTerrain.noiseSeed(noiseSeeds[0]);
    noiseTemp.noiseSeed(noiseSeeds[1]);
    noiseLand.noiseSeed(noiseSeeds[2]);
    noiseLand2.noiseSeed(noiseSeeds[2] + 1);
    noiseElevation2.noiseSeed(noiseSeeds[3]);

    function getNoiseValue(noise, loc, mul = 1) {
        return noise.get((loc.x + WORLD_RADIUS) * mul, (loc.y + WORLD_RADIUS) * mul, (loc.z + WORLD_RADIUS) * mul);
    }

    function getTemperature(loc) {
        const tempNoise = (getNoiseValue(noiseTemp, loc, 2) - 0.5) * 0.75;
        const temp = 1 - Math.abs((loc.z + tempNoise) / WORLD_RADIUS);
        return temp;
    }

    function getGeology(loc) {
        const temp = getTemperature(loc)
        let elevation = getNoiseValue(noiseTemp, loc, 0.5);
        let elevation2 = getNoiseValue(noiseElevation2, loc, 2) - 0.3;
        let elevation3 = getNoiseValue(noiseElevation2, loc, 3.8) - 0.3;

        let elevationLand = getNoiseValue(noiseElevation2, loc, 4.1);
        elevation += elevation2 * 0.3 + elevation3 * 0.15;

        let shoreline = (elevation - LAND_CUTOFF) / (1 - LAND_CUTOFF)
        let land = Math.max(0, shoreline);
        land = Math.min(1, land * (0.5 + elevationLand))

        let cold = 1 - temp;
        cold = Math.max(0, Math.min(1, 3 * (cold - 0.5) + 0.5));

        // let icey = temp < 0.15 ? Math.max(0, Math.min(1.0, 1.5 - temp * 5)) : 0;
        let icey = Math.min(1, Math.max(0, ICE_RANGE - temp + land * land) / ICE_RANGE);

        let tropic = Math.max(0, temp - TROPIC_RANGE) / (1 - TROPIC_RANGE); // Math.round(temp * temp * 9) / 9;
        tropic = tropic * 0.8;

        let rocky = Math.min(1, Math.max(0, land - ROCKY_CUTOFF) / (1 - ROCKY_CUTOFF));
        // rocky = Math.max(rocky, land * cold);

        return {
            land,
            temp,
            icey,
            tropic,
            rocky,
            shoreline,
            elevation
        }
    }

    function getElevation(res) {
        let geology = getGeology(res);
        let val = Math.sqrt(geology.icey + geology.land * 2 + geology.rocky * 15);
        val += val > 0 ? 0.1 : 0;
        let effect = Math.min(2.0, val) * 0.9 + 0.1;

        let noise = WORLD_RADIUS +
            effect * (getNoiseValue(noiseTerrain, res, 5) - 0.2) * 0.025;

        return noise;
    }

    function getGeoLocInfo(loc) {

        const geology = getGeology(loc);

        let temp = geology.temp;

        let r = 1,
            b = 0,
            g = 0;

        let landEffect = Math.sqrt(geology.land)
        let landColor1 = colorRGBToArray(gradientLand1.rgbAt(landEffect));
        let landColor2 = colorRGBToArray(gradientLand2.rgbAt(landEffect));
        let landColor3 = colorRGBToArray(gradientLand3.rgbAt(landEffect));
        let landColor4 = colorRGBToArray(gradientLand4.rgbAt(landEffect));

        let nv = (getNoiseValue(noiseLand, loc, 4) + getNoiseValue(noiseLand, loc, 2)) * 0.5
        let nv2 = getNoiseValue(noiseLand2, loc, 2.5)
        let landTrans = Math.max(0, Math.min(1, 2 * (nv - 0.5) + 0.5));
        let landTrans2 = Math.max(0, Math.min(1, 2 * (nv2 - 0.3) + 0.5));

        let isLand = geology.land > 0 ? 1 : 0;

        let [rl, gl, bl] = colorLerp(landColor1, landColor2, landTrans);
        [rl, gl, bl] = colorLerp([rl, gl, bl], landColor4, landTrans2);

        let cold = 1 - geology.temp;
        cold = Math.max(0, Math.min(1, 4 * (cold - 0.6) + 0.6));
        cold = cold * cold * 0.75 + 0.25;

        [rl, gl, bl] = colorLerp(landColor3, [rl, gl, bl], cold)

        let surfaceType = SURFACETYPE.LAND;

        let k = Math.max(0, Math.min(1.0, 1.3 - geology.temp * 4));
        if (geology.icey && !isLand) {
            surfaceType = SURFACETYPE.ICESEA;
            b = Math.pow(k, 0.2);
            g = Math.min(1.0, k + (gl * isLand))
            r = Math.min(1.0, k + (rl * isLand))
        } else {
            let icedOver = false;
            if (geology.icey) {
                let iceNoise = getNoiseValue(noiseIce, loc, 9) + k * 0.65 - 0.25;
                if (iceNoise > 0.25) {
                    iceNoise = Math.min(1, iceNoise);
                    [r, g, b] = colorLerp([1, 1, 1], [rl, gl, bl], iceNoise);
                    icedOver = true;
                    surfaceType = SURFACETYPE.ICELAND;
                }
            }
            if (!icedOver) {
                if (geology.land > 0) {
                    [r, g, b] = [rl, gl, bl];
                } else {
                    surfaceType = SURFACETYPE.SEA;
                    let shoreEffect = Math.max(0, Math.min(1, 1 - Math.abs(geology.shoreline) * 4));
                    shoreEffect = Math.pow(shoreEffect, 3) * 0.3;
                    const DEEPNESS = 0.2;
                    [r, g, b] = [
                      Math.max(0, shoreEffect * 0.5 - DEEPNESS),
                      Math.max(0, Math.min(1.0, geology.tropic + shoreEffect - DEEPNESS)),
                      Math.max(0, Math.min(1.0, temp + 0.5 + shoreEffect - DEEPNESS))];
                }
            }
        }

        return {
            color: [r, g, b],
            geology,
            loc,
            surfaceType
        };
    }

    return {
        getElevation,
        getGeoLocInfo
    };

}