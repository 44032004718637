export function Queue() {
  this.queue = {};
  this.tail = 0;
  this.head = 0;
}

// Add an element to the end of the queue.
Queue.prototype.enqueue = function(element) {
  this.queue[this.tail++] = element;
  return this.tail - 1;
}

// Add an element to the end of the queue.
Queue.prototype.remove = function(id) {
  delete this.queue[id];
}

Queue.prototype.hasSize = function() {
  while (this.tail !== this.head) {
    const element = this.queue[this.head];
    if (element)
      return true;
    delete this.queue[this.head++];
  }
  return false;
};

// Delete the first element of the queue.
Queue.prototype.dequeue = function() {
  while (this.tail !== this.head) {
    const element = this.queue[this.head];
    delete this.queue[this.head++];
    if (element)
      return element;
  }
}