// @ts-nocheck

import * as h3 from "h3-js";
import * as THREE from 'three';
import { MeshBuffersToThreeJs } from '@/visual/world/mesh';
import { createGeneratorContext } from '@/visual/world/generators';
import { GenerateMesh, GeoIndexCache } from './generator';

export {
  GeoIndexCache
};

const material = new THREE.MeshPhongMaterial({
  color: 0xffffff,
  vertexColors: true
  // flatShading: true,
  // transparent: true,
  // side: THREE.DoubleSide,
  // clippingPlanes: [localPlane],
  // clipShadows: true
});

let __world = null;
let total = 0;

function WorkerCallback(event) {

  const { index, geomesh } = event.data;
  total += geomesh.sizeOfBuffer;
  const geometry = MeshBuffersToThreeJs(geomesh);
  const meshObj = new THREE.Mesh(geometry, material);
  __world.add(meshObj);

}

const workers = [];
let workerCurIndex = 0;

function addWorker() {
  const worker = new Worker('./worker.js', { type: 'module' });
  worker.onmessage = WorkerCallback
  workers.push(worker);
}

for (let i = 0; i < 2; i++)
  addWorker();

function DoWork(WORLD_INDEX, WORLD_CONFIG) {
  if (workers.length == 0)
    RunSyncWork({
      WORLD_INDEX,
      WORLD_CONFIG
    })
  else
    workers[(workerCurIndex++) % workers.length].postMessage({
      WORLD_INDEX,
      WORLD_CONFIG
    });

}

function RunSyncWork(event) {
  const {
    WORLD_CONFIG,
    WORLD_INDEX,
  } = event

  const generatorContext = createGeneratorContext(WORLD_CONFIG);
  const geomesh = GenerateMesh(WORLD_INDEX, generatorContext);

  WorkerCallback({
    data: {
      index: WORLD_INDEX,
      geomesh
    }
  });
};

export function GeoMeshes(world, WORLD_CONFIG) {
  total = 0;
  const res0Indices = h3.getRes0Indexes();
  __world = world
  res0Indices.forEach(index => {
    DoWork(index, WORLD_CONFIG)
  });
}
