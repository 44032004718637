import { GenerateBody } from '@/visual/pawn/body';
import * as THREE from 'three';
import { RegenerateChunk, ChunksByIndex } from '@/visual/settlement/settlement-loader';

import {
  METER_TO_WORLD,
  SAMP_RANGE,
  worldToTile,
  tileToWorld
} from '@/visual/settlement/base.js';

import { Queue } from './queue';

import { loadTracks } from '@/visual/pawn/draw-utils'

import {
  createCharacter,
  getCharacterParts,
} from '@/visual/pawn/character'

import { iuToWU } from '@/visual/pawn/utils';
import { animatePart } from '@/visual/pawn/animation';
import {
  OrientationState,
  partGenerators
} from '@/visual/pawn/toon';

const loader = new THREE.TextureLoader();
const texture = loader.load('../img/shadow.png');

const geometryShadow = new THREE.PlaneGeometry(1, 1);
const array = geometryShadow.attributes.position.array;
const TILE_SIZE = 0.1;
for (let i = 0; i < array.length; i += 3) {
  array[i + 0] = array[i + 0] * TILE_SIZE * 2 / METER_TO_WORLD
  array[i + 2] = -array[i + 1] * TILE_SIZE * 2 / METER_TO_WORLD
  array[i + 1] = 0.01;
}

const materialShadow = new THREE.MeshBasicMaterial({
  map: texture,
  color: 0x555555,
  depthWrite: false,
  transparent: true,
  blending: THREE.SubtractiveBlending,
});

function createDropShadow() {
  const plane = new THREE.Mesh(geometryShadow, materialShadow);
  plane.castShadow = false;
  plane.receiveShadow = false;
  plane.position.y = -0.5;
  plane.scale.x = plane.scale.z = 1.5;
  return plane;

}



function createToonPawn(root) {

  const character = createCharacter();

  const character3d = new THREE.Object3D();
  // character3d.position.y = -1;
  root.add(character3d);
  const invCos = 1 / Math.cos(Math.PI / 4);
  character3d.scale.y = Math.min(invCos, Math.sqrt(invCos + 0.4));
  root.add(createDropShadow());

  const {
    partMargin,
  } = character;

  const animState = {
    loc: 0,
    partsByName: {},
    animationName: 'walk',
    objs: {},
    objs3d: {},
    tracksByName: {},
    character,
    character3d,
    centerX: 250,
    centerY: 250,
    minY: -iuToWU(partMargin) - 0.8,
    orientation: 0,
    horizontal: -1,
    animationOffset: Math.random(),
    order: 1
  };

  const parts = animState.parts = getCharacterParts(character);

  const tracks = animState.tracks = parts.map((part, i) => {
    const { name, delay } = part;
    const track = {
      name,
      keys: {},
      delay
    };
    animState.partsByName[name] = part;
    animState.tracksByName[name] = track;
    partGenerators[part.type](part, i, animState);
    return track;
  });

  const update = animState.update = time => {

    const frame = ((time + animState.animationOffset) * 16 * 1.5) % 16

    animState.loc = frame;
    for (let ti in tracks) {
      const track = tracks[ti];
      const part = animState.partsByName[track.name];
      let loc = animState.loc;
      if (animState.order < 0) {
        loc = 16 - animState.loc
      }
      animatePart(loc, track, part.animate)
    }

    character3d.position.y =
      (Math.sin(frame * (Math.PI * 2) / 16 * 2) + 1) * 0.25;


  };

  const load = animState.load = name => {
    loadTracks(name, animState.tracks, parts)
  };


  animState.setOrientation = (angle) => {

    angle = (angle - Math.PI / 4) * 180 / Math.PI;
    angle = ((angle % 360) + 360) % 360;

    const orientationState = Math.round(angle / 90 - 0.5);

    if (animState.orientationState === orientationState)
      return;

    // console.log('orientationState', orientationState, angle)

    let state = OrientationState[orientationState];

    animState.orientation = state.orientation;
    animState.horizontal = state.horizontal;
    animState.orientationState = orientationState;
    animState.order = state.order;

    animState.animationName = animState.animationName.split('_')[0] + state.name;
    load(animState.animationName);
  }


  animState.setOrientation(1);


  return animState;

}


export function PawnControl(sceneController, jobQueue) {

  this.pawns = [];
  this.idlePawns = new Queue();

  const voxelMap = sceneController.scene.settlement;

  const heightMap = new Float32Array(400 * 400);
  heightMap.fill(-100);

  this.setYAt = (xi, zi, y) => {
    const index = xi + zi * 400;
    heightMap[index] = y;
    // console.log('heightMap[index]', heightMap[index])
  };

  this.getYAt = (x, z) => {
    x = worldToTile(x);
    z = worldToTile(z);
    const index = x + z * 400;
    if (heightMap[index] > -100)
      return heightMap[index];

    const cellW = voxelMap.getCellHeight(x, z);

    if (cellW == voxelMap.verticleTileCount)
      return 0;

    const zf = (cellW - voxelMap.verticleTileCount / 2) * voxelMap.tileWorldSize;
    heightMap[index] = zf + 0.045;
    return heightMap[index];
  }

  this.addPawn = () => {
    // const pawn = GenerateBody();

    const pawn = {
      node: new THREE.Object3D(),
      time: 0,
    }

    pawn.update = (time) => {
      pawn.time = pawn.baseTime + time;
    }

    this.pawns.push(pawn);
    pawn.time = (this.pawns.length * 2 + Math.random()) * 0.5;
    pawn.baseTime = pawn.time;
    sceneController.scene.add(pawn.node);
    const scale = 0.7;
    pawn.node.scale.set(METER_TO_WORLD * scale, METER_TO_WORLD * scale, METER_TO_WORLD * scale);
    this.idlePawns.enqueue(pawn);
    pawn.id = this.pawns.length - 1;
    pawn.animState = createToonPawn(pawn.node);
    pawn.speed = 0.75 + Math.random() * 2;

  };

  this.update = (time) => {


    while (jobQueue.hasSize()) {
      let pawn = this.idlePawns.dequeue();
      if (!pawn)
        break;
      let job = jobQueue.dequeue();
      pawn.currentJob = job;
      console.log('Giving Pawn a Job:', pawn.id, pawn.currentJob.id);

      pawn.currentJob.time = pawn.time + 3;
      pawn.currentJob.pawnCancel = () => {
        pawn.currentJob = null;
        this.idlePawns.enqueue(pawn);
      };
    }



    let regenChunks = {};

    for (let i = 0; i < this.pawns.length; i++) {
      const pawn = this.pawns[i]
      const myTime = pawn.time;
      const rad = 7 + (i * 9) % 5;
      const runTime = (myTime + pawn.animState.animationOffset) / 24 * Math.PI * 2;

      const angle = (myTime + Math.sin(runTime * 2) * 0.01) * 2.5 * METER_TO_WORLD / rad * pawn.speed;
      // const angle = (myTime + Math.sin(myTime * 24) * 0.01) * 2.5 * METER_TO_WORLD / rad * pawn.speed;

      let x = Math.cos(angle) * rad;
      let z = Math.sin(angle) * rad;

      const worldTileCount = 400;
      const chunkTileCount = 80;
      const chunkTileCountMargin = chunkTileCount + 2;
      const VERTICAL_CHUNK_SIZE = 20;
      const worldSize = 40;
      const tileToWorldUnit = worldSize / worldTileCount;

      if (pawn.currentJob) {
        const currJob = pawn.currentJob;
        x = tileToWorld(currJob.ti);
        z = tileToWorld(currJob.tj);


        if (currJob.time < pawn.time) {
          let dyi = 1;
          const currY = this.getYAt(x, z);
          let biomVal = 1;

          if (currJob.job >= 3) {
            dyi = 0;
            biomVal = 0;
          }

          let newY = currY + METER_TO_WORLD * (dyi * 2 - 1);


          console.log('Y Change', currJob.job, currY, newY, currJob.ti, currJob.tj)
          this.setYAt(currJob.ti, currJob.tj, newY);

          const k = Math.floor(currJob.y / tileToWorldUnit + VERTICAL_CHUNK_SIZE / 2) + dyi;
          // const gridX = Math.floor(currJob.ti / chunkTileCount) * chunkTileCount;
          // const gridY = Math.floor(currJob.tj / chunkTileCount) * chunkTileCount;
          // const voxelX = currJob.ti - gridX;
          // const voxelY = currJob.tj - gridY;

          const chunkIndex = voxelMap.setCell(currJob.ti, currJob.tj, k, 0, biomVal);
          const index2d = currJob.ti + currJob.tj * 400;
          heightMap[index2d] = -100;

          // const tileHIndex = voxelX + voxelY * chunkTileCountMargin;
          // const tileIndex3 = tileHIndex * VERTICAL_CHUNK_SIZE + k;
          // const chunkId = gridY * worldTileCount + gridX;
          // ChunksByIndex[chunkId].cells[tileIndex3] = biomVal;

          // if (voxelX == 0) {
          //   const tileHIndex = chunkTileCountMargin - 2 + voxelY * chunkTileCountMargin;
          //   const tileIndex3 = tileHIndex * VERTICAL_CHUNK_SIZE + k;
          //   const chunkId = gridY * worldTileCount + gridX - chunkTileCount;
          //   ChunksByIndex[chunkId].cells[tileIndex3] = biomVal;
          //   regenChunks[chunkId] = true;
          //
          // } else if (voxelX == chunkTileCountMargin - 1) {
          //
          //   const tileHIndex = 1 + voxelY * chunkTileCountMargin;
          //   const tileIndex3 = tileHIndex * VERTICAL_CHUNK_SIZE + k;
          //   const chunkId = gridY * worldTileCount + gridX + chunkTileCount;
          //   ChunksByIndex[chunkId].cells[tileIndex3] = biomVal;
          //   regenChunks[chunkId] = true;
          //
          // }
          // if (voxelY == 0) {
          //   const tileHIndex = voxelX + (chunkTileCountMargin - 2) * chunkTileCountMargin;
          //   const tileIndex3 = tileHIndex * VERTICAL_CHUNK_SIZE + k;
          //   const chunkId = (gridY - chunkTileCount) * worldTileCount + gridX;
          //   ChunksByIndex[chunkId].cells[tileIndex3] = 1;
          //   regenChunks[chunkId] = true;
          //
          // } else if (voxelY == chunkTileCountMargin - 1) {
          //
          //   const tileHIndex = voxelX + 1 * chunkTileCountMargin;
          //   const tileIndex3 = tileHIndex * VERTICAL_CHUNK_SIZE + k;
          //   const chunkId = (gridY + chunkTileCount) * worldTileCount + gridX;
          //   ChunksByIndex[chunkId].cells[tileIndex3] = 1;
          //   regenChunks[chunkId] = true;
          //
          // }

          console.log('Finished Job:', currJob.id, currJob.ti, currJob.tj, "regen:", chunkIndex);
          regenChunks[chunkIndex] = true;
          currJob.cancel();
        }
      }


      pawn.node.position.x = x;
      pawn.node.position.z = z;

      const characterAngle = -angle;
      // pawn.node.rotation.y = -angle;
      pawn.node.rotation.y = -sceneController.inputHandler.viewX + Math.PI / 2;

      // pawn.animState.loc = time % 16;
      pawn.animState.setOrientation(characterAngle - pawn.node.rotation.y)
      pawn.animState.update(time * Math.sqrt(pawn.speed))


      // const x = pawn.position.x;
      // const z = pawn.position.z;

      const y = Math.max(
        this.getYAt(x + SAMP_RANGE, z + SAMP_RANGE),
        this.getYAt(x + SAMP_RANGE, z - SAMP_RANGE),
        this.getYAt(x - SAMP_RANGE, z - SAMP_RANGE),
        this.getYAt(x - SAMP_RANGE, z + SAMP_RANGE)) + METER_TO_WORLD * 0.5;



      pawn.node.position.y += (y - pawn.node.position.y) * 0.1;
      pawn.update(time);

    }
    for (let chunkId in regenChunks) {
      RegenerateChunk(chunkId)
    }

  };

}