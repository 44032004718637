// @ts-nocheck
export function CreateViewManager({ renderer, stats }) {

  console.log('CreateViewManager');
  const viewManager = {
    update: null
  }


  const animate = function() {
    stats.forEach(stat => stat.begin());
    viewManager.update && viewManager.update();
    stats.forEach(stat => stat.end());
    requestAnimationFrame(animate);

  };

  animate();
  return viewManager
}

export function GetViewManager(context) {
  if (!window.VIEW_MANAGER) {
    window.VIEW_MANAGER = CreateViewManager(context);
  }
  return window.VIEW_MANAGER;
}
