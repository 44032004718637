// @ts-nocheck
import * as THREE from 'three';
import {
    NormalizeMesh,
} from './mesh-modify';

export function MeshBuffersToThreeJs(meshBuffers, normalize = false) {
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.BufferAttribute(meshBuffers.position, 3));
  if (meshBuffers.normal)
    geometry.setAttribute('normal', new THREE.BufferAttribute(meshBuffers.normal, 3));
  if (meshBuffers.color)
    geometry.setAttribute('color', new THREE.BufferAttribute(meshBuffers.color, 3));
  if (meshBuffers.uv)
    geometry.setAttribute('uv', new THREE.BufferAttribute(meshBuffers.uv, 2));
  if (meshBuffers.uv2)
    geometry.setAttribute('uv2', new THREE.BufferAttribute(meshBuffers.uv2, 2));
  geometry.setIndex(new THREE.BufferAttribute(meshBuffers.indices, 1));
  if (normalize)
    geometry.computeVertexNormals();

  return geometry
}


export function MeshToAttributeBuffers(mesh, normalize = true) {

    if (normalize)
      NormalizeMesh(mesh);
  
    const positions = mesh.vertices.flatMap(v => [v.p.x, v.p.y, v.p.z]);
    const normals = mesh.vertices.flatMap(v => [v.n.x, v.n.y, v.n.z]);
    const colors = mesh.vertices.flatMap(v => v.c);
    const indices = mesh.indices;
  
    const bytesInFloat32 = 4;
    const bytesInInt16 = 2;
    const positionsArray = new Float32Array(
      new SharedArrayBuffer(bytesInFloat32 * positions.length));
    const normalsArray = new Float32Array(
      new SharedArrayBuffer(bytesInFloat32 * normals.length));
    const colorsArray = new Float32Array(
      new SharedArrayBuffer(bytesInFloat32 * colors.length));
    const indicesArray = new Uint16Array(
      new SharedArrayBuffer(bytesInInt16 * indices.length));
  
    const sizeOfBuffer = bytesInFloat32 * positions.length +
      bytesInFloat32 * normals.length +
      bytesInFloat32 * colors.length +
      bytesInInt16 * indices.length;
  
    positionsArray.set(positions, 0);
    normalsArray.set(normals, 0);
    colorsArray.set(colors, 0);
    indicesArray.set(indices, 0);
  
    const buffers = {
      position: positionsArray,
      normal: normalsArray,
      color: colorsArray,
      indices: indicesArray,
      sizeOfBuffer
    };
  
    if (mesh.vertices[0] && mesh.vertices[0].t) {
      const uvs = mesh.vertices.flatMap(v => [v.t.x, v.t.y]);
      const uvsArray = new Float32Array(new SharedArrayBuffer(bytesInFloat32 * uvs.length));
      uvsArray.set(uvs, 0);
      buffers.uv = uvsArray;
    }
  
    if (mesh.vertices[0] && mesh.vertices[0].t2) {
      const uvs2 = mesh.vertices.flatMap(v => [v.t.x, v.t.y]);
      const uvs2Array = new Float32Array(new SharedArrayBuffer(bytesInFloat32 * uvs2.length));
      uvs2Array.set(uvs2, 0);
      buffers.uv2 = uvs2Array;
    }
  
    return buffers;
  
  }