import { Object3D } from 'three';

export function VoxelChunk(CHUNK_CONFIG) {


  const {
    tileOffsetU,
    tileOffsetV,
    chunkTileCount,
    verticleTileCount,
    tileWorldSize,
    dataChannels
  } = CHUNK_CONFIG;

  const chunkTileCountMargin = chunkTileCount + 2;
  const totalCellCount = chunkTileCountMargin * chunkTileCountMargin * verticleTileCount;

  CHUNK_CONFIG.chunkTileCountMargin = chunkTileCountMargin;
  CHUNK_CONFIG.cells = new Uint8Array(new SharedArrayBuffer(totalCellCount * dataChannels));

  this.config = CHUNK_CONFIG;
  const materialChannel = VOXEL_DATA_CHANNELS.MATERIAL;

  Object.assign(this, CHUNK_CONFIG);

  this.getCellHeight = (u, v) => {
    u += 1;
    v += 1;
    const c = materialChannel;
    for (let w = verticleTileCount - 1; w > 0; w--) {
      const cellIndex = c + (w + (u + v * chunkTileCountMargin) * verticleTileCount) * dataChannels;
      if (this.cells[cellIndex])
        return w;
    }
    return verticleTileCount;
  };

  this.setCell = (u, v, w, c, value) => {
    u += 1;
    v += 1;
    const cellIndex = c + (w + (u + v * chunkTileCountMargin) * verticleTileCount) * dataChannels;
    this.cells[cellIndex] = value;
  };

}

// material: 0-255  //soil/stone/granite/slate/....

export const VOXEL_MATERIAL_PROFILE = {

}


export const VOXEL_DATA_CHANNELS = {
  MATERIAL: 0,
  TEMPERATURE: 1,
  VEGETATION: 2,
  HYDRATION: 3,
  RES_TYPE_0: 4,
  RES_AMOUNT_0: 5,
  RES_TYPE_1: 6,
  RES_AMOUNT_1: 7,
  RES_TYPE_2: 8,
  RES_AMOUNT_2: 9,
  RES_TYPE_3: 10,
  RES_AMOUNT_3: 11,
  CHANNEL_COUNT: 12
};


const EXAMPLE_MAP = {
  worldTileCount: 400,
  chunkTileCount: 80,
  verticleTileCount: 20,
  worldSize: 40,
  dataChannels: VOXEL_DATA_CHANNELS.CHANNEL_COUNT
};

export function VoxelMap(voxelMapConfig) {

  const {
    geoIndex,
    worldTileCount,
    chunkTileCount,
    verticleTileCount,
    worldSize,
    dataChannels
  } = voxelMapConfig;

  Object.assign(this, voxelMapConfig);

  this.node = new Object3D;
  const chunkCount = worldTileCount / chunkTileCount;
  const tileWorldSize = worldSize / worldTileCount;

  this.tileWorldSize = tileWorldSize;

  this.getCellHeight = (u, v) => {
    const chunkU = Math.floor(u / chunkTileCount);
    const chunkV = Math.floor(v / chunkTileCount);
    const chunkIndex = chunkU + chunkV * chunkCount;

    u -= chunkU * chunkTileCount;
    v -= chunkV * chunkTileCount;

    if (!this.chunks[chunkIndex])
      return verticleTileCount;

    return this.chunks[chunkIndex].getCellHeight(u, v)

  };

  this.setCell = (u, v, w, c, value) => {
    const chunkU = Math.floor(u / chunkTileCount);
    const chunkV = Math.floor(v / chunkTileCount);
    const chunkIndex = chunkU + chunkV * chunkCount;

    u -= chunkU * chunkTileCount;
    v -= chunkV * chunkTileCount;

    this.chunks[chunkIndex].setCell(u, v, w, c, value);
    return chunkIndex;
  };

  this.chunks = {};

  const centerOffsetX = (chunkTileCount * 2 - 0.5) * tileWorldSize;
  const centerOffsetZ = (chunkTileCount * 2 - 0.5) * tileWorldSize;

  for (let i = 0; i < worldTileCount; i += chunkTileCount) {
    for (let j = 0; j < worldTileCount; j += chunkTileCount) {
      const chunkIndex = (i / chunkTileCount) + (j / chunkTileCount) * chunkCount;
      const worldOffsetX = (i / worldTileCount) * worldSize - centerOffsetX;
      const worldOffsetZ = (j / worldTileCount) * worldSize - centerOffsetZ;

      this.chunks[chunkIndex] = new VoxelChunk({
        geoIndex,
        chunkIndex,
        tileOffsetU: i,
        tileOffsetV: j,
        worldOffsetX,
        worldOffsetZ,
        chunkTileCount,
        verticleTileCount,
        tileWorldSize,
        dataChannels
      });
    }
  }


}