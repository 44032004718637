// @ts-nocheck

import * as THREE from 'three';
import Stats from './stats';
import EventEmitter from 'events';
import { CreateInputController } from './input-controller';
import NoopMouseViewControl from './noop-viewer';


function getParentWithSize(element) {
  const bothSizes = (element.offsetWidth || element.innerWidth) && (element.offsetHeight || element.innerHeight)
  if (element.parentNode && !(bothSizes))
    return getParentWithSize(element.parentNode)
  return element;
}

function addStatsDisplay(num) {
  const stats = new Stats();
  document.getElementById('graphics-stats-section').appendChild(stats.dom);
  stats.showPanel(num);
  stats.dom.className = 'graphics-stats graphics-stats-' + num;
  return stats;
}

export function CreateContext(name = 'default', element = document.body) {

  function getCanvasSize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    return { width, height };
  }

  const { width, height } = getCanvasSize();

  console.log('CreateContext', name);
  const renderer = new THREE.WebGLRenderer({
    // alpha: true,
    // antialias: true
  });
  renderer.setSize(width, height);

  const stats = [
  addStatsDisplay(0),
  addStatsDisplay(1),
  addStatsDisplay(2),
  ];

  const inputController = new CreateInputController(renderer.domElement);
  inputController.attach(new NoopMouseViewControl());

  const emitter = new EventEmitter();

  window.addEventListener('resize', onWindowResize, false);

  function onWindowResize() {
    const { width, height } = getCanvasSize();
    renderer.setSize(width, height);
    emitter.emit('resize');
  }

  renderer.domElement.id = name + '-view';
  return {
    renderer,
    stats,
    inputController,
    emitter,
    getCanvasSize,
  }
}

export function GetContext(name = 'default', element = null) {
  const global_name = 'VIEW_CONTEXT_' + name;
  if (window[global_name]) {
    if (element)
      element.appendChild(window[global_name].renderer.domElement)
    return window[global_name]
  }
  window[global_name] = CreateContext(name, element)
  element.appendChild(window[global_name].renderer.domElement)
  return window[global_name]
}

