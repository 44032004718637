// @ts-nocheck

import { GetViewManager } from './view-manager'
import { GetContext } from './context.ts';
import NoopMouseViewControl from './noop-viewer';

const noopMouseViewControl = new NoopMouseViewControl();

export function UnregisterScene(name) {

  if (!window.SCENES)
    window.SCENES = {};
  if (!window.SCENE_FACTORIES)
    window.SCENE_FACTORIES = {};

  if (window.SCENES[name]) {
    window.SCENES[name] = null;
    const context = GetContext();
    GetViewManager(context).update = null;
  }
}

export function RegisterScene(name, sceneFactor) {
  if (!window.SCENES)
    window.SCENES = {};
  if (!window.SCENE_FACTORIES)
    window.SCENE_FACTORIES = {};

  if (!window.SCENES[name]) {
    const context = GetContext();
    window.SCENES[name] = sceneFactor(context)
    window.SCENE_FACTORIES[name] = sceneFactor
  }
}

export function GetSceneController(name) {
  return window.SCENES[name];
}

export function ActivateScene(name) {
  const ctrl = GetSceneController(name);
  const context = GetContext();
  context.inputController.attach((ctrl && ctrl.inputHandler) || noopMouseViewControl);
  GetViewManager(context).update = ctrl && ctrl.update;
  return ctrl;
}