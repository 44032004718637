import { Vector3 } from 'three';



export function MouseViewControl() {

  // const inputController = new CreateInputController();

  this.viewD = 10;
  this.viewX = 0;
  this.viewY = 0;

  this.mouseDX = 0;
  this.mouseDY = 0;
  this.mouseX = 0;
  this.mouseY = 0;

  this.offsetX = 0
  this.offsetY = 0

  this.hasMouseCtx = false;
  this.ignoreClick = false;
  this.isMouseDown = false;

  this.maxZoom = 1000;
  this.minZoom = 0.001;

  this.dx = 0;
  this.dy = 0;

  this.scale = 0.75;
  this.scrollSpeed = 20;
  this.mouseSpeed = 0.01;


  this.inputHandler = {};
  this.update = () => {};

  this.button = {};

  let mx = this.offsetX
  let my = this.offsetY
  let cx = 0;
  let cy = 0;
  let dd = 0;

  let center = new Vector3

  this.center = center

  this.translateButton = 2;
  this.rotateButton = 0;
  this._scale = this.scale;

  this.keyUp = function() {}
  this.keyDown = function() {}

  this.applyUpdate = function(context, ortho) {
    let camera = context.camera

    this._scale += (this.scale - this._scale);


    let d = this.viewD * 0.8;
    if (!ortho)
      d *= this._scale



    let vy = -2 * d / context.width * 0.1;
    let vx = vy;
    this.ddx = vx;
    this.ddy = vy;

    let x = Math.cos(this.viewX) * Math.cos(this.viewY);
    let z = Math.sin(this.viewX) * Math.cos(this.viewY);
    let y = Math.sin(this.viewY);
    camera.zoom = 1 / this.scale
    camera.updateProjectionMatrix()

    cx += (this.offsetX - mx);
    cy += (this.offsetY - my);
    if (dd) {
      cx *= d / dd
      cy *= d / dd
    }
    camera.setViewOffset(1, 1, cx, cy, 1, 1)
    mx = this.offsetX
    my = this.offsetY

    if (!dd) {
      dd = d
    }

    let sy = this.element.offsetHeight / this.element.offsetWidth
    let oy = (1 - sy) * 0.5

    camera.setViewOffset(1, 1,
      this.offsetX * 0.5, this.offsetY * 0.5 + oy, 1, sy)


    camera.position.x = x * d + center.x;
    camera.position.y = y * d + center.y;
    camera.position.z = z * d + center.z;
    camera.lookAt(center)
  }


  this.attach = function(inputController) {
    inputController.attach(this);
  };

  this.click = function(event) {
    if (this.ignoreClick) {
      this.ignoreClick = false;
    } else {
      if (this.inputHandler.click)
        this.inputHandler.click(event);
    }
  };


  this.mouseDown = function(event) {
    event.preventDefault();
    this.mouseX = event.pageX;
    this.mouseY = event.pageY;

    this.button[event.button] = true;
    if (event.button === 0)
      this.isMouseDown = true;
    if (event.button === 2)
      this.isMouseDown2 = true;

    this.update()
    return false;
  };


  this.mouseUp = function(event) {
    if (event.button === 0)
      this.isMouseDown = false;
    this.button[event.button] = false;
    this.update()
  };

  this.mouseMove = function(event) {

    this.mouseDX = event.pageX - this.mouseX;
    this.mouseDY = event.pageY - this.mouseY;
    this.mouseX = event.pageX;
    this.mouseY = event.pageY;

    if (this.button[this.translateButton]) {
      this.offsetX += this.mouseDX * this.ddx
      this.offsetY += this.mouseDY * this.ddy
    }

    if (this.button[this.rotateButton]) {
      this.ignoreClick = true;

      const speed = this.mouseSpeed * Math.min(1, this.scale * this.scale * this.scale);

      this.viewX += this.mouseDX * speed;
      this.viewY += this.mouseDY * speed;

      if (this.maxViewY !== undefined && this.viewY > this.maxViewY)
        this.viewY = this.maxViewY;
      if (this.minViewY !== undefined && this.viewY < this.minViewY)
        this.viewY = this.minViewY;

      if (this.maxViewX !== undefined && this.viewX > this.maxViewX)
        this.viewX = this.maxViewX;
      if (this.minViewX !== undefined && this.viewX < this.minViewX)
        this.viewX = this.minViewX;

    }

    if (this.inputHandler.mouseMove)
      this.inputHandler.mouseMove(event);

    this.update()
  };



  this.mouseScroll = function(delta) {
    let scale = Math.pow(1.06, -delta);
    this.scale = scale * this.scale;
    this.scale = Math.max(Math.min(this.scale, this.maxZoom), this.minZoom);
    this.update();
  }


}