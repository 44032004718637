export default function MouseViewControl() {

  this.attach = function(inputController) {
    inputController.attach(this);
  };

  this.click = function(event) {}
  this.mouseDown = function(event) {}
  this.mouseUp = function(event) {}
  this.mouseMove = function(event) {}
  this.mouseScroll = function(delta) {}

}