import WalkTrackJson from './animations/walk_tracks.json';
import WalkSideTrackJson from './animations/walk_side_tracks.json';

export function createExtrudeFromPath(pts, width, smooth = 150) {
  const pts0 = [];
  const pts1 = [];
  for (let i = 0; i < pts.length; i++) {
    const p0 = pts[i];
    const p1 = pts[i + 1];

    const tan0 = p0[2];
    const p0_0 = [p0[0] + tan0[0] * width, p0[1] + tan0[1] * width];
    const p0_1 = [p0[0] - tan0[0] * width, p0[1] - tan0[1] * width];

    pts0.push(p0_0)
    pts1.push(p0_1)

    if (!p1)
      continue;

    const tan1 = p1[2];
    const p1_0 = [p1[0] + tan1[0] * width, p1[1] + tan1[1] * width];
    const p1_1 = [p1[0] - tan1[0] * width, p1[1] - tan1[1] * width];

    const l = smooth;
    const ex0 = [tan0[1] * l, -tan0[0] * l];
    const ex1 = [-tan1[1] * l, tan1[0] * l];

    // pts0.push([ex0[0] + p0[0], ex0[1] + p0[1]])
    // pts0.push([ex1[0] + p1[0], ex1[1] + p1[1]])
    // pts1.push([ex0[0] + p0[0], ex0[1] + p0[1]])
    // pts1.push([ex1[0] + p1[0], ex1[1] + p1[1]])

    for (let j = 1; j < 20; j++) {
      const t0_1 = j / 20;
      const t0_2 = t0_1 * t0_1;
      const t0_3 = t0_1 * t0_1 * t0_1;

      const e0 = 2 * t0_3 - 3 * t0_2 + 1;
      const e1 = t0_3 - 2 * t0_2 + t0_1;
      const e2 = t0_3 - t0_2;
      const e3 = -2 * t0_3 + 3 * t0_2;

      const x = e0 * p0[0] + e1 * (ex0[0]) - e2 * (ex1[0]) + e3 * p1[0];
      const y = e0 * p0[1] + e1 * (ex0[1]) - e2 * (ex1[1]) + e3 * p1[1];

      const de0 = 6 * t0_2 - 6 * t0_1;
      const de1 = 3 * t0_2 - 4 * t0_1 + 1;
      const de2 = 3 * t0_2 - 2 * t0_1;
      const de3 = -6 * t0_2 + 6 * t0_1;

      const rnd_x = (Math.random() - 0.5) * 60
      const rnd_y = (Math.random() - 0.5) * 60

      const dx = de0 * p0[0] + de1 * (ex0[0]) - de2 * (ex1[0]) + de3 * p1[0] + rnd_x;
      const dy = de0 * p0[1] + de1 * (ex0[1]) - de2 * (ex1[1]) + de3 * p1[1] + rnd_y;

      const factor = width / Math.sqrt(dx * dx + dy * dy);
      const tx = -dy * factor;
      const ty = dx * factor;

      pts0.push([x + tx, y + ty])
      pts1.push([x - tx, y - ty])
    }

  }

  pts1.reverse();
  pts0.push(...pts1);
  return pts0;
}

export function createPathFromCurve(pts) {
  const points = [];
  for (let i = 0; i < pts.length; i++) {
    points.push(pts[i]);
    const p0 = pts[i];
    const p1 = pts[(i + 1) % pts.length];

    const tan0 = p0[3];
    const tan1 = p1[2];

    if (tan0[0] * tan0[0] + tan0[1] * tan0[1] + tan1[0] * tan1[0] + tan1[1] * tan1[1] == 0)
      continue;

    // points.push([tan0[0] + p0[0], tan0[1] + p0[1]]);
    // points.push([tan1[0] + p1[0], tan1[1] + p1[1]]);


    for (let j = 1; j < 20; j++) {
      const t0 = 1 - j / 20;
      const t1 = 1 - t0;
      const e0 = t0 * t0 * t0;
      const e1 = t0 * t0 * t1;
      const e2 = t0 * t1 * t1;
      const e3 = t1 * t1 * t1;

      const x = e0 * p0[0] + e1 * (tan0[0] + p0[0]) + e2 * (tan1[0] + p1[0]) + e3 * p1[0];
      const y = e0 * p0[1] + e1 * (tan0[1] + p0[1]) + e2 * (tan1[1] + p1[1]) + e3 * p1[1];

      points.push([x, y]);
    }

  }

  return points;
}

export function createCanvas() {
  const canvas = document.createElement('canvas');
  return canvas;
}

export function saveTracks(animationName, tracks) {
  if (tracks) {
    localStorage.setItem(animationName + '_tracks', JSON.stringify(tracks));
  } else {
    localStorage.setItem('_tracks', JSON.stringify(animationName));
  }
}

const baseTracks = {
  'walk_tracks': WalkTrackJson,
  'walk_side_tracks': WalkSideTrackJson,
}

export function loadTracks(animationName, tracks, parts) {

  if (!tracks) {
    tracks = animationName;
    animationName = ''
  }
  const fullName = animationName + '_tracks';

  let ctracks = baseTracks[fullName]

  if (!ctracks) {
    trs = localStorage.getItem(fullName);
    if (!trs)
      return;
    ctracks = JSON.parse(trs);
  }

  if (!ctracks)
    return;

  tracks.forEach(track => {

    const part = parts.find(pt => pt.name == track.name);
    let ltr = ctracks.find(tr => tr.name == track.name);
    if (!ltr && part.name2)
      ltr = ctracks.find(tr => tr.name == part.name2);
    track.keys = ltr ? ltr.keys : {};
  });
}

export function clearTracks(tracks) {
  tracks.forEach(track => track.keys = {});
  saveTracks(tracks);
}

export function translate(points, x, y) {
  points.forEach(p => {
    p[0] += x;
    p[1] += y;
  });
}

export function getTransform(klass) {
  return {
    left: klass.left,
    top: klass.top,
    angle: klass.angle,
    skewX: klass.skewX,
    skewY: klass.skewY,
    scaleX: klass.scaleX,
    scaleY: klass.scaleY,
    imageIndex: klass.imageIndex || 0,
  };
}

export function setTransform(klass, transform) {
  klass.setOptions(transform);
  klass.setCoords()
  // klass.imageIndex = transform.imageIndex || 0;
}


const _tempPoint = {
  left: 0,
  top: 0,
};

const _temp = {
  left: 0,
  top: 0,
};

function easeTime(t) {

  // t = (1 - Math.cos(t * Math.PI)) / 2;
  // t = (1 - Math.cos(t * Math.PI)) / 2;
  // const tk = (1 - Math.cos(t * Math.PI)) / 2;
  // t = (t + tk) * 0.5;
  return t;
}

export function interpolateTransformPoint(p, t0, t1, t) {
  if (!t1) {
    p[0] = t0[0];
    p[1] = t0[1];
    return _tempPoint;
  }
  t = easeTime(t);

  p[0] = t0[0] * (1 - t) + t1[0] * t;
  p[1] = t0[1] * (1 - t) + t1[1] * t;
  // return _tempPoint;


}

export function interpolateTransform(t0, t1, t) {
  t = easeTime(t);


  _temp.left = t0.left * (1 - t) + t1.left * t;
  _temp.top = t0.top * (1 - t) + t1.top * t;


  let a0 = t0.angle;
  let a1 = t1.angle;

  if (Math.abs(t0.angle - t1.angle) > 180) {
    let s = t0.angle < 0 ? -1 : 1;

    if (a0 * s > a1 * s)
      a1 += 360 * s;
    else
      a1 -= 360 * s;

    // a1 = a1 * s;
  }

  // console.log('a0, a1', a0, a1, t0.angle, t1.angle)

  _temp.angle = a0 * (1 - t) + a1 * t;
  _temp.skewX = t0.skewX * (1 - t) + t1.skewX * t;
  _temp.skewY = t0.skewY * (1 - t) + t1.skewY * t;
  _temp.scaleX = t0.scaleX * (1 - t) + t1.scaleX * t;
  _temp.scaleY = t0.scaleY * (1 - t) + t1.scaleY * t;
  return _temp;
}