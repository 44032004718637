// @ts-nocheck

import * as THREE from 'three';
import { MouseViewControl } from '@/visual/scene/mouse-viewer'
import * as h3 from "h3-js";
import { GeoMeshes, GeoIndexCache } from '@/visual/world/mesh-geo';
import { WORLD_CONFIG, createGeneratorContext } from '@/visual/world/generators';
// import MouseSpaceMixin from '@/mixins/mouseSpace';
import calculateTileDetail from '@/visual/settlement/utils/calculateTileInfo';

import {
  EffectComposer,
  RenderPass,
  EffectPass,
  SavePass,
  KawaseBlurPass,
  KernelSize,
  BoxBlurPass,
  ChromaticAberrationEffect,
  VignetteEffect,
  ToneMappingEffect,
  BloomEffect,
} from 'postprocessing';

// import MouseSpaceMixin from '@/mixins/mouseSpace';

function WorldState(WORLD_CONFIG) {
  this.WORLD_CONFIG = WORLD_CONFIG;
  this.geoIndexCache = new GeoIndexCache();
  this.generatorContext = createGeneratorContext(this.WORLD_CONFIG);
  this.fetchInfo = function (geoIndex) {
    return this.geoIndexCache.fetchInfo(geoIndex, this.generatorContext);
  }
}

export const DefaultWorld = new WorldState(WORLD_CONFIG)

function CreateWorld() {
  const world = new THREE.Object3D;
  GeoMeshes(world, DefaultWorld.WORLD_CONFIG);
  return world;
}

function CreateSpaceScene() {
  console.log('CreateSpaceScene');
  const scene = new THREE.Scene();

  let light = new THREE.DirectionalLight(
    0xffffff);
  light.position.set(0.4, 0.0, 0.1).normalize();
  scene.add(light);

  light = new THREE.DirectionalLight(0x333366);
  light.position.set(-1, -0.5, -0.1).normalize();
  scene.add(light);

  light = new THREE.AmbientLight(0x404040); // soft white light
  scene.add(light);

  const loader = new THREE.CubeTextureLoader();
  const texture = loader.load([
    './img/bg/space-posx.jpg',
    './img/bg/space-negx.jpg',
    './img/bg/space-posy.jpg',
    './img/bg/space-negy.jpg',
    './img/bg/space-posz.jpg',
    './img/bg/space-negz.jpg',
  ]);
  // const texture = loader.load([
  //   './img/bg/px.png',
  //   './img/bg/nx.png',
  //   './img/bg/py.png',
  //   './img/bg/ny.png',
  //   './img/bg/pz.png',
  //   './img/bg/nz.png',
  // ]);
  // texture.encoding = THREE.sRGBEncoding;
  scene.background = texture;

  scene.setWorld = (world) => {
    if (scene.world)
      scene.remove(scene.world);
    scene.world = world;
    if (world)
      scene.add(world);
  };


  return scene;
}


export function CreateSpaceSceneController(context) {
  console.log('CreateSpaceSceneController');

  const { renderer, stats, inputController } = context;

  const scene = CreateSpaceScene();
  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  const mouseViewer = new MouseViewControl();
  // mouseViewer.attach(inputController);
  mouseViewer.element = renderer.domElement;
  mouseViewer.viewY = 0.25 * Math.PI;
  mouseViewer.viewX = -0.25 * Math.PI;

  const CENTER = new THREE.Vector3();
  const mouseRay = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  const earth = new THREE.Sphere(CENTER, 2);
  const hitLoc = new THREE.Vector3();
  const _hitLoc = new THREE.Vector3();

  const composer = new EffectComposer(renderer);

  const bloomEffect = new BloomEffect({
    luminanceThreshold: 0.9,
    intensity: 1.8,
    luminanceSmoothing: 0.05
  });

  composer.addPass(new RenderPass(scene, camera));
  composer.addPass(new EffectPass(camera, bloomEffect));
  composer.addPass(new EffectPass(camera, new ChromaticAberrationEffect()));
  composer.addPass(new EffectPass(camera, new VignetteEffect()));
  // composer.addPass(new KawaseBlurPass({ height: 400 }));


  const mouseInfo = {
    lat: 0,
    lng: 0,
    index: 0,
    callback: null
  };
  const ray = raycaster.ray;
  let mouseFlag = null;

  function getInfo(hitLoc) {

    _hitLoc.copy(hitLoc);
    // hitLoc.normalize();
    scene.world.worldToLocal(hitLoc);
    const by = hitLoc.z;
    const bx = Math.sqrt(hitLoc.x * hitLoc.x + hitLoc.y * hitLoc.y);
    mouseInfo.lat = Math.atan2(by, bx) * 180 / Math.PI;
    mouseInfo.lng = Math.atan2(hitLoc.y, hitLoc.x) * 180 / Math.PI;
    mouseInfo.index = h3.geoToH3(mouseInfo.lat, mouseInfo.lng,
      DefaultWorld.WORLD_CONFIG.WORLD_RESOLUTION);
    return mouseInfo;
  }


  mouseViewer.inputHandler.click = () => {
    if (ray.intersectSphere(earth, hitLoc)) {
      if (!scene.world)
        return
      const { index } = getInfo(hitLoc);

      const indexInfo = DefaultWorld.fetchInfo(index);
      const { loc, surfaceType } = indexInfo;
      const { buildable } = calculateTileDetail(indexInfo)
      console.log('indexInfo', indexInfo);

      // if (buildable) {
      //   console.log('index', index);
      //   // MouseSpaceMixin.selectedLocation.value = index;
      // }
    }

  };

  const sceneController = {
    scene,
    camera,
    mouseInfo,
    inputHandler: mouseViewer,
    unloadWorld: function () { scene.setWorld(null); },
    worldLoading: false,
    loadWorld: function () {
      if (sceneController.worldLoading)
        return;
      console.log('loadWorld');
      sceneController.worldLoading = true;
      const world = CreateWorld();
      scene.setWorld(world);
    },
    update: function () {

      mouseViewer.applyUpdate({
        camera,
        width: window.innerWidth
      });
      mouseRay.set(
        (mouseViewer.mouseX / window.innerWidth) * 2 - 1,
        -(mouseViewer.mouseY / window.innerHeight) * 2 + 1);
      // raycaster.setFromCamera(mouseRay, camera);
      // Flags.updateFlags(0, mouseViewer.scale);
      const rotateAmount = 0.0005;
      if (scene.world) {
        scene.world.rotation.x = -Math.PI / 2;
        scene.world.rotation.z += rotateAmount;
      }
      mouseViewer.viewX -= rotateAmount;

      camera.aspect = 1;
      camera.updateProjectionMatrix();

      composer.render();
      // renderer.render(scene, camera);
    }
  };

  return sceneController;

}
