import Generator_Earthlike from './earthlike.js';
import { SURFACETYPE } from './types'
import { WORLD_CONFIG } from './default';

export {
    SURFACETYPE,
    WORLD_CONFIG,
};

export const generators = {
    'earthlike': Generator_Earthlike
};

export function createGeneratorContext(WORLD_CONFIG) {

    const {
        WORLD_RESOLUTION,
        WORLD_RADIUS,
        GENERATOR
    } = WORLD_CONFIG;

    const generatorConstructor = generators[GENERATOR]
    const generatorContext = generatorConstructor(WORLD_CONFIG);
    Object.assign(generatorContext, {
        WORLD_RESOLUTION,
        WORLD_RADIUS
    });

    return generatorContext;

}