import {
    EmpytMesh,
    AddToMesh,
    MeshToAttributeBuffers,
} from '@/visual/world/mesh';
import { SURFACETYPE } from '@/visual/world/generators';

import * as h3 from "h3-js";
import * as THREE from 'three';

export function GeoToV3(coords, generatorContext) {
    const WORLD_RADIUS = generatorContext.WORLD_RADIUS;
    return coords.map(coord => {
        let a1 = coord[1] * Math.PI / 180;
        let a2 = coord[0] * Math.PI / 180;
        let ax = Math.cos(a1);
        let ay = Math.sin(a1);
        let bx = Math.cos(a2) * (WORLD_RADIUS);
        let by = Math.sin(a2) * (WORLD_RADIUS);

        let res = new THREE.Vector3(ax * bx, ay * bx, by);
        let elevation = generatorContext.getElevation(res) / WORLD_RADIUS;

        res.x *= elevation;
        res.y *= elevation;
        res.z *= elevation;

        return res;
    })
}

export function PolygonToMesh(poly, { color, loc, surfaceType }, generatorContext) {

    let mesh = {
        vertices: poly.map(v => {
            let col = color;
            if (surfaceType == SURFACETYPE.SEA) {
                const info = generatorContext.getGeoLocInfo(v);
                col = info.color;
            }
            return ({
                p: v,
                c: col
            })
        }),
        indices: []
    };

    mesh.vertices.push({ p: loc, c: color });
    let center = poly.length;

    for (let i = 0; i < poly.length - 1; i++) {
        mesh.indices.push(i, i + 1, center);
    }
    mesh.indices.push(poly.length - 1, 0, center);

    return mesh;
}

export function GeoIndexCache() {
    const PolyMeshes = {};
    const GeoLocInfoByIndex = {};

    this.fetchInfo = function(geoIndex, generatorContext) {
        if (GeoLocInfoByIndex[geoIndex])
            return GeoLocInfoByIndex[geoIndex];
        const hexCenterCoordinates = h3.h3ToGeo(geoIndex);
        const loc = GeoToV3([hexCenterCoordinates], generatorContext)[0];

        GeoLocInfoByIndex[geoIndex] = generatorContext.getGeoLocInfo(loc);
        GeoLocInfoByIndex[geoIndex].index = geoIndex;
        GeoLocInfoByIndex[geoIndex].loc = loc;

        return GeoLocInfoByIndex[geoIndex];
    }

    this.fetchMesh = function(index, generatorContext) {

        if (PolyMeshes[index]) {
            return PolyMeshes[index];
        }

        let boundary = h3.h3ToGeoBoundary(index);
        let nodeInfo = this.fetchInfo(index, generatorContext);
        let poly = GeoToV3(boundary, generatorContext);
        let polyMesh = PolygonToMesh(poly, nodeInfo, generatorContext);
        PolyMeshes[index] = polyMesh;
        return polyMesh;
    }

}

const geoIndexCache = new GeoIndexCache();

export function GenerateMesh(res0Index, generatorContext) {

    const { WORLD_RESOLUTION } = generatorContext;
    const mesh = EmpytMesh();
    const subs = h3.h3ToChildren(res0Index, WORLD_RESOLUTION);

    subs.forEach(childIndex => {
        const polyMesh = geoIndexCache.fetchMesh(childIndex, generatorContext)
        AddToMesh(mesh, polyMesh);
    });

    return MeshToAttributeBuffers(mesh);
}