export const METER_TO_WORLD = 0.10;

export const SAMP_RANGE = 0.4 * METER_TO_WORLD;


export function worldToTile(x) {
  return Math.floor((x + 20) * 10);
}

export function tileToWorld(x) {
  return x / 10 - 20 + METER_TO_WORLD * 0.5;
}