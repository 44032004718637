import { Vector3 } from 'three';
import EventEmitter from 'events';


export function MouseViewControl() {

  // const inputController = new CreateInputController();

  this.viewD = 10;
  this.viewX = 0;
  this.viewY = 0;

  this._viewX = 0;

  this.mouseDX = 0;
  this.mouseDY = 0;
  this.mouseX = 0;
  this.mouseY = 0;

  this.offsetX = 0
  this.offsetY = 0

  this.hasMouseCtx = false;
  this.ignoreClick = false;
  this.isMouseDown = false;

  this.maxZoom = 0.1;
  this.minZoom = 0.0001;

  this.dx = 0;
  this.dy = 0;

  this.scale = 1;
  this.scrollSpeed = 20;
  this.mouseSpeed = 0.01;


  this.inputHandler = {};
  this.update = () => {};

  this.button = {};

  this.hover = new Vector3;
  this.selection = new Vector3;

  let mx = this.offsetX
  let my = this.offsetY
  let cx = 0;
  let cy = 0;
  let dd = 0;

  let center = new Vector3

  this.center = center

  this.translateButton = 0;
  this.rotateButton = 2;

  this.dxdx = 1;
  this.dydx = 1;

  this.dxdy = 0;
  this.dydy = 0;

  this._scale = this.scale;

  this.keyStates = {};
  // this.emitter

  this.applyUpdate = function(context, ortho) {

    let moveX = 0;
    let moveY = 0;
    let moveSpeed = -0.75 / Math.sqrt(this._scale);

    if (this.keyStates['a'])
      moveX = -1;
    if (this.keyStates['d'])
      moveX = 1;
    if (this.keyStates['w'])
      moveY = -1;
    if (this.keyStates['s'])
      moveY = 1;


    this.offsetX += (moveX * this.dxdx + moveY * this.dxdy) * moveSpeed
    this.offsetY += (moveY * this.dydx + moveX * this.dydy) * moveSpeed

    this._scale += (this.scale - this._scale) * 0.5;
    this._viewX += (this.viewX - this._viewX) * 0.2;
    this._viewY += (this.viewY - this._viewY) * 0.2;

    let camera = context.camera

    let x = Math.cos(this._viewX) * Math.cos(this.viewY);
    let z = Math.sin(this._viewX) * Math.cos(this.viewY);
    let y = Math.sin(this.viewY);

    let d = this.viewD * 5;

    center.x = this.offsetX;
    center.z = this.offsetY;

    camera.position.x = x * d + center.x;
    camera.position.y = y * d + center.y;
    camera.position.z = z * d + center.z;

    camera.target = center;

    camera.lookAt(center)

    camera.zoom = 1 / (this._scale)
    camera.updateProjectionMatrix()

    let vy = -2 / window.innerWidth * 0.1;
    let vx = vy;
    let k = this._scale * 30;
    let m = 2 * d / window.innerWidth * (k);
    this.dxdy = -Math.cos(this._viewX) * m;
    this.dydy = Math.cos(this._viewX) * m;
    this.dxdx = -Math.sin(this._viewX) * m;
    this.dydx = -Math.sin(this._viewX) * m;

  }


  this.attach = function(inputController) {
    inputController.attach(this);
  };

  this.click = function(event) {
    if (this.ignoreClick) {
      this.ignoreClick = false;
    } else {
      if (this.inputHandler.click)
        this.inputHandler.click(event);
    }
  };


  this.mouseDown = function(event) {
    event.preventDefault();
    this.mouseX = event.pageX;
    this.mouseY = event.pageY;

    this.selection.copy(this.hover);
    this.hasSelection = true;

    this.button[event.button] = true;
    if (event.button === 0)
      this.isMouseDown = true;
    if (event.button === 2)
      this.isMouseDown2 = true;

    this.update()
    return false;
  };

  this.keyUp = function() {
    this.keyStates[event.key] = false;
  }


  this.keyDown = function(event) {

    this.keyStates[event.key] = true;

    switch (event.key) {
      case 'q':
        this.viewX += Math.PI / 4;
        break;
      case 'e':
        this.viewX -= Math.PI / 4;
        break;

    }


    // consoleadd.log('event', event)
  }

  this.mouseUp = function(event) {

    if (this.hasSelection) {
      this.emit('apply:selection', this.selected, this.hover);
    }

    if (event.button === 0)
      this.isMouseDown = false;
    this.button[event.button] = false;
    this.hasSelection = false;

    // if (this.inputHandler.mouseUp)
    // return this.inputHandler.mouseUp(event);
  };

  this.mouseMove = function(event) {

    this.mouseDX = event.pageX - this.mouseX;
    this.mouseDY = event.pageY - this.mouseY;
    this.mouseX = event.pageX;
    this.mouseY = event.pageY;

    if (this.hasSelection)
      return;
    // if (this.inputHandler.mouseMove)
    // return this.inputHandler.mouseMove(event);

    if (this.button[this.translateButton]) {
      this.offsetX += this.mouseDX * this.dxdx + this.mouseDY * this.dxdy
      this.offsetY += this.mouseDY * this.dydx + this.mouseDX * this.dydy
    }

    if (this.button[this.rotateButton]) {
      this.ignoreClick = true;

      const speed = this.mouseSpeed
      // * Math.min(1, this.scale * this.scale * this.scale);

      this.viewX += this.mouseDX * speed;
      this.viewY += this.mouseDY * speed;

      if (this.maxViewY !== undefined && this.viewY > this.maxViewY)
        this.viewY = this.maxViewY;
      if (this.minViewY !== undefined && this.viewY < this.minViewY)
        this.viewY = this.minViewY;

      if (this.maxViewX !== undefined && this.viewX > this.maxViewX)
        this.viewX = this.maxViewX;
      if (this.minViewX !== undefined && this.viewX < this.minViewX)
        this.viewX = this.minViewX;

    }


    this.update()
  };



  this.mouseScroll = function(delta) {
    let scale = Math.pow(1.07, -delta);
    this.scale = scale * this.scale;
    this.scale = Math.max(Math.min(this.scale, this.maxZoom), this.minZoom);
    this.update();
  }


}

MouseViewControl.prototype = Object.create(EventEmitter.prototype)