
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { GetContext } from '@/visual/scene/context';
import { RegisterScene, ActivateScene, GetSceneController } from '@/visual/scene/scene-manager';
import { CreateSpaceSceneController } from '@/visual/space-scene';
import { CreateCreatureSceneController } from '@/visual/creature-scene';
import { CreateSettlementSceneController } from '@/visual/settlement-scene';

@Component({
  components: {
  },
})
export default class App extends Vue {
  drawer = null

  @Ref('gameview1') readonly gameview1!: any;
  @Ref('gameview2') readonly gameview2!: any;

  professions = [
    'fullstack developer',
    'graphics engineer',
    'machine learning',
    'web3',
  ]

  professionsSections = [{
    languages: ['Javascript', 'Typescript', 'CSS/SASS', 'GraphQL', 'MySQL', 'PHP'],
    'frameworks / libraries': ['Vue', 'React', 'Wordpress'],
    'platforms / technologies': ['AWS', 'Docker', 'K8s', 'Kafka'],
    tools: ['Docker', 'Jira', 'Git'],
  }, {
    languages: ['C/C++', 'Python', 'Javascript', 'Typescript'],
    'frameworks / libraries': ['ThreeJs', 'BabylonJs', 'Ogre3d'],
    platforms: ['WebGL', 'AWS', 'DirectX 9/10 11/12'],
    tools: ['Unity', 'Blender', 'Fusion 360', 'Gimp', ' Inkscape', 'Jira', 'Git'],
  }, {
    languages: ['Python', 'Javascript', 'Typescript'],
    libraries: ['Pandas', 'TensorFlow', 'PyTorch'],
    platforms: ['AWS', 'Kafka'],
    tools: ['Jira', 'Git', 'Stable Diffusion'],
  }, {
    languages: ['Solidity', 'Javascript', 'Typescript', 'Python'],
    contracts: ['ERC20', 'ERC777', 'ERC721'],
    tools: ['Ganache', 'Truffle', 'Jira', 'Git'],
  }]


  dotdotdots: string[] = [];

  cards = [{
    url: '/img/cards/card0.png',
    tags: ['webgl', 'threejs', 'shaders', 'ai art', 'glsl', 'typescript', 'animation'],
    view: 'creature',
  }, {
    url: '/img/cards/card1.png',
    tags: ['webgl', 'threejs', 'webworkers', 'procedural', 'h3', 'typescript', 'geometry buffers'],
    view: 'space',
  }, {
    url: '/img/cards/card2.png',
    tags: ['webgl', 'threejs', 'webworkers', 'procedural', 'shaders', 'animation', 'voxels', 'typescript', 'geometry buffers'],
    view: 'settlement',
  }]

  skillsClass = '';
  selectedProf: string | null = null;
  hoverProf: string | null = null;
  isSkillsVisible = false;
  isSkillsVisibleTimeout = 0;
  selectedScene = '';

  get selectedProfName(): any {
    return this.hoverProf ?? this.selectedProf ?? '';
  }

  get selectedProfDetail(): any {
    return this.professionsSections[this.professions.indexOf(this.selectedProfName)] || {};
  }

  updateSkillClass() {
    clearTimeout(this.isSkillsVisibleTimeout);
    if (this.skillsClass) {
      this.isSkillsVisible = true;
    } else {
      this.isSkillsVisibleTimeout = setTimeout(() => { this.isSkillsVisible = false }, 500);
    }
  }

  selectProf(prof: any) {
    this.skillsClass = this.selectedProf == prof ? '' : 'visible';
    this.updateSkillClass()
    if (this.selectedProf == prof) {
      this.selectedProf = null;
      return;
    }
    this.selectedProf = prof;
  }

  showProf(prof: any) {
    this.hoverProf = prof;
    if (this.selectedProf)
      return;
    this.skillsClass = prof == null ? '' : 'visible';
    this.updateSkillClass()
  }

  selectScene(sceneName: string) {
    this.skillsClass = '';
    this.selectedProf = null;
    this.updateSkillClass();
    this.selectedScene = sceneName;

    switch (sceneName) {
      case 'creature':
        ActivateScene('creature');
        break;
      case 'space':
        ActivateScene('space');
        const spaceCtrl = GetSceneController('space');
        spaceCtrl.loadWorld();
        break;
      case 'settlement':
        const sceneControllerName = 'settlement';
        // UnregisterScene(sceneControllerName);
        RegisterScene(sceneControllerName, CreateSettlementSceneController);
        const sceneController = ActivateScene(sceneControllerName)
        sceneController.load({
          geoIndex: '82756ffffffffff'
        });
    }
  }

  mounted() {
    const context = GetContext('default', this.gameview1);
    RegisterScene('space', CreateSpaceSceneController);
    RegisterScene('creature', CreateCreatureSceneController);

    this.selectScene('creature');


    this.professions.forEach(async (_, index) => {
      this.dotdotdots.push('');
      await new Promise(res => setTimeout(res, Math.random() * 1200));
      setInterval(() => {
        this.dotdotdots[index] += '.';
        if (this.dotdotdots[index].length > 3)
          this.dotdotdots[index] = '';
        this.dotdotdots = this.dotdotdots.slice();
      }, 1200);
    })

  }
};
