// @ts-nocheck

import * as THREE from 'three';

import {
    EffectComposer,
    RenderPass,
    EffectPass,
    SavePass,
    KawaseBlurPass,
    KernelSize,
    BoxBlurPass,
    ChromaticAberrationEffect,
    VignetteEffect,
    ToneMappingEffect,
    BloomEffect,
} from 'postprocessing';


function ColorRGB255ToVector3(r, g, b) {
    return new THREE.Vector3(r / 255, g / 255, b / 255)
}

function ScaleColor(color, f) {
    return new THREE.Vector3(
        color.x * f,
        color.y * f,
        color.z * f,
    );
}

const Creatures = [
    { url: '/content/creatures/indexor_random_creature_nft_black_background_ice_jeweled_1781256d-30e0-4282-acc3-6570f60f4ec6-removebg.png' },
    { url: '/content/creatures/indexor_random_creature_nft_black_background_ice_metal_2680b989-8f20-48cc-951e-99ec78dc7002-transformed.png' },
    { url: '/content/creatures/indexor_random_creature_nft_black_background_dff1a2a5-2125-49c1-afaa-4ea5c40364d4-removebg-preview.png' },
    { url: '/content/creatures/indexor_random_creature_nft_black_background_32c73e2f-0c31-4b66-b0b5-81f6b6100a92-removebg-preview.png' },
    { url: '/content/creatures/indexor_random_creature_bright_metal_dumb_nimble_nft_black_back_12a1cd31-4048-48c6-9f09-ff38009364ad-removebg-preview.png' },
    { url: '/content/creatures/indexor_random_creature_bright_metal_dumb_nimble_nft_black_back_2f7ef302-0bdc-4937-bc39-8687dc9d2e48-removebg-preview.png' },
    { url: '/content/creatures/indexor_random_creature_nft_black_background_f6e36f7e-502b-480f-9cc0-9366be6eaa75-removebg-preview.png' },
    { url: '/content/creatures/indexor_random_creature_nft_black_background_ice_colorful_jewel_5fa6a9bc-f374-4469-900c-752b99270a4c-removebg-preview.png' },
    { url: '/content/creatures/indexor_random_creature_nft_black_background_ice_colorful_jewel_30b98427-9eec-4ec2-8630-021ecfb423d6-removebg-preview.png' },
    { url: '/content/creatures/indexor_random_creature_nft_black_background_ice_jeweled_12d56153-384f-4395-b724-dc87cbb9cd19-removebg-preview.png' },
    { url: '/content/creatures/' },
    { url: '/content/creatures/' },
];

const Plants = [
    [
        { url: '/content/plants/large/indexor_ui_design_sci-fi_colour_palette_alien_plants_black_back_245a18de-7d84-47a4-b01a-8e807db09833-removebg-preview.png' },
        { url: '/content/plants/large/indexor_ui_design_sci-fi_colour_palette_alien_plants_black_back_a603c264-dc73-4350-a954-95f851c7647e-removebg-preview.png' },
        { url: '/content/plants/large/indexor_ui_design_sci-fi_colour_palette_alien_plants_black_back_ab9e211f-5aa3-4b8a-8255-b15ea25f800d-removebg-preview.png' },
    ],
    [
        { url: '/content/plants/large/indexor_ui_design_sci-fi_colour_palette_alien_plants_black_back_01aceb05-e070-4784-aace-5914d86b1a8e-removebg-preview.png' },
        { url: '/content/plants/large/indexor_ui_design_sci-fi_colour_palette_alien_plants_black_back_60310701-1816-47f2-933f-73667c43dfbf-removebg-preview.png' },
        { url: '/content/plants/large/indexor_ui_design_sci-fi_colour_palette_alien_plants_black_back_a12b220f-993d-44b3-bc9e-e13c8e9a10c7-removebg-preview.png' },
        { url: '/content/plants/large/indexor_ui_design_sci-fi_colour_palette_alien_plants_black_back_f853e91e-f0bf-49af-873e-d0fc37f125b3-removebg-preview.png' }
    ],
    [],
];


const loader = new THREE.TextureLoader();
const blankTexture = new THREE.Texture();

function LoadTexture({ url }) {
    return loader.loadAsync(url)
}

const VertexShaders = {
    FullQuad: `
    varying vec2 vUv;
    
    void main() {
        vUv = uv;
        gl_Position = vec4( position, 1.0 );    
    }
  `,
    FullQuadx2: `
  varying vec2 vUv;
  
  void main() {
      vUv = uv;
      gl_Position = vec4( position * 2.0, 1.0 );    
  }
`,
    Quad: `
  varying vec2 vUv;
  uniform float time;
  
  void main() {
      vUv = uv;
      //gl_Position = vec4( position, 1.0 );    
      vec3 pos = position;
      pos.y += sin(time + (uv.x * 3.0)) * uv.y * 0.01 * (1.0 + sin(uv.x * 5.2 + 4.3 + time));
      pos.x += sin(2.2 + time * 1.2 + (uv.y)) * uv.y * 0.01 * (1.3 + sin(uv.x * 2.2 + 3.8 + time));
      vec4 modelViewPosition = modelViewMatrix * vec4(pos, 1.0);

      gl_Position = projectionMatrix * modelViewPosition; 
  }
`
}

const COLOR_WHITE = new THREE.Vector3(1, 1, 1);

function CreateSprite(texturePromise, {
    vertexShader,
    colors,
}, size = 1, x = 0, y = 0, z = 0) {
    const ratio = 1;

    const color00 = colors ? colors[0][0] : COLOR_WHITE;
    const color01 = colors ? colors[0][1] : COLOR_WHITE;
    const color10 = colors ? colors[1][0] : COLOR_WHITE;
    const color11 = colors ? colors[1][1] : COLOR_WHITE;

    const material = new THREE.ShaderMaterial({
        transparent: true,
        uniforms: {
            time: { value: 0.0 },
            uTexture: blankTexture,
            color00: { value: color00 }, // LEFT UP
            color01: { value: color01 }, // RIGHT UP
            color10: { value: color10 }, // LEFT DOWN
            color11: { value: color11 }, // RIGHT DOWN
        },
        vertexShader,
        fragmentShader: `
        varying vec2 vUv;
        uniform sampler2D uTexture;
        uniform vec3 color00;
        uniform vec3 color01;
        uniform vec3 color11;
        uniform vec3 color10;
         
        void main() {
            vec4 color = texture2D(uTexture, vUv);
            vec3 color0 = mix(color00, color01, vUv.x);
            vec3 color1 = mix(color10, color11, vUv.x);
            color.rgb *= mix(color1, color0, vUv.y);
            gl_FragColor = color;
        }
      `
    });

    texturePromise.then(texture => {
        material.uniforms.uTexture = new THREE.Uniform(texture);
    });


    const sprite = new THREE.Mesh(new THREE.PlaneGeometry(size, size * ratio, 1, 1), material);

    if (x)
        sprite.position.x = x;
    if (y)
        sprite.position.y = y;
    if (z)
        sprite.position.z = z;

    return sprite;

}

const BACKGROUD_DARKNESS = 1.1;

function CreateColorQuad() {
    const ColorDarkBlue = ColorRGB255ToVector3(23, 31, 38);

    const TopColor = ScaleColor(ColorDarkBlue, 1.8 * BACKGROUD_DARKNESS);
    TopColor.x += 0.04;
    const BottomColor = ScaleColor(ColorDarkBlue, 0.7 * BACKGROUD_DARKNESS);
    BottomColor.x -= 0.03;
    BottomColor.y -= 0.02;



    const material = new THREE.ShaderMaterial({
        uniforms: {
            color00: { value: ScaleColor(TopColor, 1.3) }, // LEFT UP
            color01: { value: ScaleColor(TopColor, 1.1) }, // RIGHT UP
            color10: { value: ScaleColor(BottomColor, 0.9) }, // LEFT DOWN
            color11: { value: ScaleColor(BottomColor, 0.7) }, // RIGHT DOWN
        },
        vertexShader: VertexShaders.FullQuad,
        fragmentShader: `
        varying vec2 vUv;
        uniform vec3 color00;
        uniform vec3 color01;
        uniform vec3 color11;
        uniform vec3 color10;
         
        void main() {
            vec3 color0 = mix(color00, color01, vUv.x);
            vec3 color1 = mix(color10, color11, vUv.x);
            gl_FragColor = vec4( mix(color1, color0, vUv.y), 1.0 );
        }
      `
    });

    const quad = new THREE.Mesh(new THREE.PlaneGeometry(2, 2, 1, 1), material);
    return quad;
}

function CreateOrthoGraphicCamera(context) {

    const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);

    function onResize() {
        let { width, height } = context.getCanvasSize();
        const ratio = width / height;
        const zoom = ratio > 1 ? 1.25 : 1.55;
        width = zoom * ratio;
        height = zoom;
        camera.left = -width / 2;
        camera.right = width / 2;
        camera.top = height / 2;
        camera.bottom = -height / 2;
        camera.updateProjectionMatrix();
    }

    context.emitter.on('resize', onResize);

    onResize();

    return camera;
}

function CRGB(r, b, g) {
    return new THREE.Vector3(r, g, b);
}


const COLOR_LOWER_DARK = ScaleColor(CRGB(0.3, 0.4, 0.45), BACKGROUD_DARKNESS);
const COLOR_UP_DARK = ScaleColor(COLOR_WHITE, BACKGROUD_DARKNESS * 1.1);
const COLORS_LOWER_DARK = [
    [COLOR_UP_DARK, COLOR_UP_DARK],
    [COLOR_LOWER_DARK, COLOR_LOWER_DARK],
]

const SCENE_Y_OFFSET = -0.125;

async function CreateRenderedBlurredScene1(context) {
    console.log('CreateRenderedBlurredScene1');

    const scene = new THREE.Scene();
    const plants = [LoadTexture(Plants[0][0]), LoadTexture(Plants[0][1])];

    await Promise.all(plants);

    const plant0 = CreateSprite(plants[0], { vertexShader: VertexShaders.Quad, colors: COLORS_LOWER_DARK }, 0.8, -0.15, 0.05 + SCENE_Y_OFFSET)
    const plant1 = CreateSprite(plants[1], { vertexShader: VertexShaders.Quad, colors: COLORS_LOWER_DARK }, 0.8, 0.15, 0.05 + SCENE_Y_OFFSET)

    scene.add(CreateColorQuad());
    scene.add(plant0);
    scene.add(plant1);

    plant0.to = plant0.position.x;
    plant0.ts = 0.8
    plant1.to = plant1.position.x;
    plant1.ts = 0.7

    context.plants.push(plant0, plant1);

    await new Promise(res => setTimeout(res, 1));

    const camera = CreateOrthoGraphicCamera(context);

    const composer = new EffectComposer(context.renderer);
    composer.addPass(new RenderPass(scene, camera));
    composer.addPass(new KawaseBlurPass({}));
    composer.addPass(new SavePass());

    composer.render();

    context.composers.push(composer);
    // console.log("composer", composer);

    return composer.outputBuffer.texture;
}


async function CreateRenderedBlurredScene2(context) {
    console.log('CreateRenderedBlurredScene2');

    const scene = new THREE.Scene();

    const background = CreateRenderedBlurredScene1(context);

    const plants = [LoadTexture(Plants[1][0]), LoadTexture(Plants[1][1]), background];

    await Promise.all(plants);


    scene.add(CreateSprite(background, { vertexShader: VertexShaders.FullQuadx2 }, 1));

    const plant0 = CreateSprite(plants[0], { vertexShader: VertexShaders.Quad, colors: COLORS_LOWER_DARK }, 0.75, -0.4, -0.05 + SCENE_Y_OFFSET);
    const plant1 = CreateSprite(plants[1], { vertexShader: VertexShaders.Quad, colors: COLORS_LOWER_DARK }, 0.75, 0.4, -0.05 + SCENE_Y_OFFSET, 0);

    plant0.to = plant0.position.x;
    plant0.ts = 1.2
    plant1.to = plant1.position.x;
    plant1.ts = 1.1

    scene.add(plant0);
    scene.add(plant1);

    context.plants.push(plant0, plant1);

    await new Promise(res => setTimeout(res, 1));

    const camera = CreateOrthoGraphicCamera(context);

    const composer = new EffectComposer(context.renderer);
    composer.addPass(new RenderPass(scene, camera));
    composer.addPass(new KawaseBlurPass({ kernelSize: KernelSize.VERY_SMALL }));
    composer.addPass(new SavePass());

    composer.render();

    context.composers.push(composer);
    // console.log("composer", composer);

    return composer.outputBuffer.texture;
}


function CreateCreatureScene(context) {

    context.composers = [];

    context.plants = [];

    console.log('CreateCreatureScene');
    const scene = new THREE.Scene();
    const background = CreateRenderedBlurredScene2(context);
    scene.add(CreateSprite(background, { vertexShader: VertexShaders.FullQuadx2 }, 1));

    const creature = CreateSprite(LoadTexture(Creatures[0]), { vertexShader: VertexShaders.Quad }, 0.75, 0, 0, 0);
    context.creature = creature;

    scene.add(creature);
    scene.setCreature = () => {

    };

    return scene;
}


export function CreateCreatureSceneController(context) {
    console.log('CreateCreatureSceneController');

    const { renderer, stats, inputController } = context;

    const scene = CreateCreatureScene(context);
    const camera = CreateOrthoGraphicCamera(context);

    const composer = new EffectComposer(renderer);

    context.bloomEffect = new BloomEffect({
        luminanceThreshold: 0.9,
        intensity: 1.8,
        luminanceSmoothing: 0.05
    });

    composer.addPass(new RenderPass(scene, camera));
    composer.addPass(new EffectPass(camera, context.bloomEffect));
    composer.addPass(new EffectPass(camera, new ChromaticAberrationEffect()));
    composer.addPass(new EffectPass(camera, new VignetteEffect()));
    // composer.addPass(new KawaseBlurPass({ height: 400 }));

    let time = 0;

    const sceneController = {
        scene,
        camera,
        unload: function () { scene.setCreature(null); },
        worldLoading: false,
        load: function () {
            // scene.setWorld(world);
        },
        update: function () {

            time += 0.016666;

            context.plants.forEach(plant => {
                if (!plant.oo) {
                    plant.oo = new THREE.Vector3(plant.position.x, plant.position.y, plant.position.z)
                }
                plant.material.uniforms.time.value = time * plant.ts + plant.to;
                //  plant.position.y = plant.oo.y + Math.sin(time) * 0.015;
            })

            context.creature.position.x = Math.sin(time * 0.8) * 0.005;
            context.creature.position.y = Math.sin(time * 1.1) * 0.01 + SCENE_Y_OFFSET;

            let a = 0.5 + Math.sin(time * 0.4 + Math.cos(time*1.1)) * 0.5;
            context.bloomEffect.luminanceMaterial.threshold = 0.8 - a * a * a * 0.3;

            context.composers.forEach(comp => comp.render())
            composer.render();
            // renderer.render(scene, camera);
        }
    };

    return sceneController;

}
