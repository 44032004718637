// @ts-nocheck

import * as THREE from 'three';
import { MouseViewControl } from './settlement/settlement-viewer'
import { GenerateChunks, waterMaterial, materialCover } from './settlement/settlement-loader';
import { SettlementGenerator } from './settlement/earthlike-settlement';
import EventEmitter from 'events';
import { VoxelMap } from '@/voxel/voxel-map';
import { VOXEL_DATA_CHANNELS } from '@/voxel/voxel-map';
import { PawnControl } from '@/visual/pawn/pawn-control.js';
import { Queue } from '@/visual/pawn/queue';
import {
  METER_TO_WORLD,
} from '@/visual/settlement/base.js';

const settlementGenerator = new SettlementGenerator();

function LoadSettlement({ geoIndex }) {
  const voxelMap = new VoxelMap({
    geoIndex,
    generator: 'earthlike',
    worldSize: 40,
    worldTileCount: 400,
    chunkTileCount: 80,
    verticleTileCount: 20,
    dataChannels: VOXEL_DATA_CHANNELS.CHANNEL_COUNT
  });

  GenerateChunks(voxelMap);

  return voxelMap;
}

function CreateSettlementScene(camera) {
  const scene = new THREE.Scene();

  const sc = 1;
  const light = new THREE.DirectionalLight(
    new THREE.Color(sc, sc, sc));
  light.position.set(-4, 8, 4);
  light.castShadow = true;

  light.shadow.mapSize.width = 1024; // default
  light.shadow.mapSize.height = 1024; // default
  light.shadow.camera.near = 0.1; // default
  light.shadow.camera.far = 30; // default
  light.bias = 0.01;

  light.shadow.camera.left = -2;
  light.shadow.camera.right = 2;
  light.shadow.camera.top = 2;
  light.shadow.camera.bottom = -2;

  scene.add(light);

  // let helper = new THREE.CameraHelper(light.shadow.camera);
  // scene.add(helper);

  const ambientLight = new THREE.AmbientLight(0x404088); // soft white light
  scene.add(ambientLight);

  //Create a sphere that cast shadows (but does not receive them)
  // const sphereGeometry = new THREE.SphereGeometry(1, 32, 32);
  // const sphereMaterial = new THREE.MeshStandardMaterial({ color: 0xff0000 });
  // const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
  // sphere.castShadow = true; //default is false
  // sphere.receiveShadow = false; //default
  // sphere.position.y = 2;
  // scene.add(sphere);

  scene.loadSettlement = (payload) => {
    if (scene.loading) {
      return;
      // scene.remove(scene.settlement);
    }
    const { geoIndex } = payload;
    settlementGenerator.setIndex(geoIndex);
    scene.loading = true;
    scene.settlement = LoadSettlement(payload);
    scene.add(scene.settlement.node);
  };

  const sunViewX = Math.PI - 0.5;
  let sunViewY = Math.PI / 2;//-Math.PI;

  const colorMoon = new THREE.Color(0.3, 0.3, 0.4);
  const colorSun = new THREE.Color(0.2, 0.2, 0.2);
  const color0 = new THREE.Color(0, 0, 0);

  const eventEmitter = new EventEmitter();
  let time = 0;
  scene.eventEmitter = eventEmitter;

  // console.log('light.target', light.target)

  scene.add(light.target);
  // camera.add(light.shadow.camera);

  let start = new Date();

  scene.update = () => {
    
    const end = new Date();
    const deltaTimeInSeconds = (end - start) / 1000;
    start = end;
    time += Math.min(deltaTimeInSeconds, 0.02);
    
    eventEmitter.emit('update', time);

    let targetX = 0;
    let targetZ = 0;

    // light.target.x = 0;
    // light.target.z = 0;
    if (camera.target) {
      light.target.position.set(camera.target.x, 0, camera.target.z);
    }

    const shadowScreenSize = 800 / camera.zoom;
    light.shadow.camera.left = -shadowScreenSize;
    light.shadow.camera.right = shadowScreenSize;
    light.shadow.camera.top = shadowScreenSize;
    light.shadow.camera.bottom = -shadowScreenSize;
    light.shadow.camera.updateProjectionMatrix();
    // light.shadow.radius = 0.1;
    // scene.remove(helper);
    // helper = new THREE.CameraHelper(light.shadow.camera);
    // scene.add(helper);

    const ld = 8;
    const x = Math.sin(sunViewY) * ld;
    const y = Math.cos(sunViewX) * Math.cos(sunViewY) * ld;
    const z = Math.sin(sunViewX) * Math.cos(sunViewY) * ld;

    const sinTime = -Math.cos(sunViewY);

    const sunLum = Math.max(0, sinTime);
    // sunViewX = Math.PI - Math.cos(sunViewY + Math.PI) * 0.5;
    const lcolor = light.color;
    lcolor.r = lcolor.g = lcolor.b = Math.sqrt(sunLum);

    const moonValue = Math.sqrt(Math.max(0, -sinTime));

    lcolor.r += 0.3 * moonValue;
    lcolor.g += 0.3 * moonValue;
    lcolor.b += 0.6 * moonValue;

    const moonSun = 1 - Math.pow(Math.max(0, sinTime), 0.5);

    ambientLight.color.lerpColors(colorSun, colorMoon, moonSun);

    sunViewY += 0.001;

    light.position.set(
      x + light.target.position.x,
      Math.abs(y) + light.target.position.y,
      z + light.target.position.z);
    // light.target..set(0, 0, 0);

  };


  return scene;
}


export function CreateSettlementSceneController(context) {

  console.log('CreateSettlementSceneController');

  const { renderer, stats, inputController } = context;

  renderer.shadowMap.enabled = false;
  // renderer.shadowMap.type = THREE.PCFShadowMap; // default THREE.PCFShadowM
  renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowM
  // renderer.shadowMap.type = THREE.BasicS; // default THREE.PCFShadowM
  // renderer.toneMapping = THREE.ACESFilmicToneMapping;
  // renderer.toneMappingExposure = 1;

  // const camera = new THREE.PerspectiveCamera(75,
  //   window.innerWidth / window.innerHeight, 0.1, 1000);

  const width = window.innerWidth;
  const height = window.innerHeight;

  const camera = new THREE.OrthographicCamera(
    width / - 2, width / 2,
    height / 2, height / - 2, 0.1, 100);

  const scene = CreateSettlementScene(camera);


  const mouseViewer = new MouseViewControl();
  // mouseViewer.attach(inputController);
  mouseViewer.element = renderer.domElement;
  mouseViewer.viewY = 0.25 * Math.PI;
  mouseViewer.viewX = 0.25 * Math.PI;
  mouseViewer.scale = 0.005;

  const sceneController = {
    scene,
    camera,
    eventEmitter: scene.eventEmitter,
    inputHandler: mouseViewer,
    settlementGenerator,
    load: (payload) => {
      scene.loadSettlement(payload);
      scene.eventEmitter.emit('loaded');
    },
    update: function () {

      const width = window.innerWidth;
      const height = window.innerHeight;

      waterMaterial.uniforms.uTime.value += 0.016;
      materialCover.uniforms.uTime.value += 0.016;

      camera.left = width / - 2;
      camera.right = width / 2;
      camera.top = height / 2;
      camera.bottom = height / -2;

      camera.updateProjectionMatrix();

      mouseViewer.applyUpdate({
        camera,
        width: window.innerWidth
      });

      scene.update();

      renderer.render(scene, camera);


    }

  };

  scene.eventEmitter.on('loaded', time => {

    const jobQueue = new Queue();

    const { eventEmitter, inputHandler } = sceneController;

    const pawnControl = new PawnControl(sceneController, jobQueue);
    for (let i = 0; i < 20; i++)
      pawnControl.addPawn();

    eventEmitter.on('update', time => {

      pawnControl.update(time);

      const pawn = pawnControl.pawns[parseInt(pawnControl.pawns.length / 2)];

      inputHandler.offsetX = pawn.node.position.x;
      inputHandler.offsetY = pawn.node.position.z;
      inputHandler.center.y = pawn.node.position.y + METER_TO_WORLD;


    });

  });

  return sceneController;

}
